<template>
  <div class="container mx-auto px-4 h-full sign_in_card">
    <div
      v-if="!showDisclaimer"
      class="flex flex-col content-center items-center justify-center h-full"
    >
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg bg_login border-0"
        >
          <div class="mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <img :src="logoURL" alt="" class="login_logo mb-3" />
              <h6 class="text-xl font-bold">Sign in</h6>
            </div>
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form @submit.prevent="onSubmit()">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-md font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email"
                  class="ipField border-0 px-3 py-3 placeholder-blueGray-50 text-white bg_input text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                  v-model="email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-md font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  :type="pass_showHide ? 'text' : 'password'"
                  class="ipField border-0 px-3 py-3 placeholder-blueGray-50 text-white bg_input text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                  v-model="password"
                />
                <span
                  class="show_hide icon is-small is-right"
                  @click="toggleEye()"
                >
                  <svg
                    v-show="pass_showHide"
                    class="svg-inline--fa fa-eye fa-w-18"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="eye"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                    ></path>
                  </svg>
                  <svg
                    v-show="!pass_showHide"
                    class="svg-inline--fa fa-eye-slash fa-w-20"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="eye-slash"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                    ></path>
                  </svg>
                </span>
              </div>
              <!-- <div class="text-left mt-6" v-show="!isAgree"> -->
              <div class="text-left mt-6" v-show="false">
                <input
                  class="relative mr-2 checkbox_agree"
                  type="checkbox"
                  v-model="isAgree"
                />
                <label class="text-xs"
                  >I agree to the &#169;Apollodart<span class="ml-2"
                    ><router-link
                      to="/terms"
                      class="underline"
                      style="color: var(--activeTextColor)"
                      >Terms</router-link
                    >
                    &nbsp;and&nbsp;<router-link
                      to="/privacy"
                      class="underline"
                      style="color: var(--activeTextColor)"
                      >Policies</router-link
                    >&nbsp;
                  </span></label
                >
              </div>
              <div class="text-center mt-6">
                <input
                  class="curser text-white active:bg-green-800 text-sm font-semibold bgClr uppercase px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  style="background: var(--activeTextColor)"
                  type="submit"
                  :value="isLoading == true ? 'please wait..' : 'Sign In'"
                  :disabled="!isAgree"
                />
              </div>
            </form>
            <div class="text-red-500">
              {{ errcode }}
            </div>
            <div class="flex flex-wrap mt-6 relative">
              <div class="w-1/2">
                <small
                  @click="() => $router.push('/auth/reset')"
                  class="curser __forget_password"
                  >Forgot password?</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Disclaimer
      :login_Response_data="login_Response"
      @disagreed="disagreeDisclaimer"
      :agreed="agreedAgreement"
      v-if="showDisclaimer"
    />
    <div
      class="absolute w-full text-sm"
      style="color: var(--textColor); bottom: 3%"
    >
      <span style="color: var(--textColor); right: 0" class="float-right mr-2">
        Contact our
        <router-link
          to="/support"
          class="underline"
          style="color: var(--activeTextColor)"
        >
          support
        </router-link>
        team for help
      </span>
    </div>
  </div>
</template>
<script>
import Logo from "@/assets/img/apollodart_logo_white.png";
import darklogoImage from "@/assets/img/apollodart_logo_white.png";
import lightlogoImage from "@/assets/img/apollodart_logo_black.png";
// import Logo from "@/assets/img/drillinktech_logo.png";
import api from "../../api/services";
import { defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";
import URLS from "../../config";
import zulipInit from "zulip-js";
export default {
  data() {
    return {
      isRedirectUserManagement: false,
      isRedirectSST: false,
      Logo,
      email: "",
      errcode: "",
      isLoading: false,
      password: "",
      pass_showHide: false,
      showDisclaimer: false,
      login_Response: {},
      isAgree: true,
      device_id: "",
      toUpdateElement: 1,
    };
  },
  components: {
    Disclaimer: defineAsyncComponent(() => import("./Disclaimer.vue")),
  },
  methods: {
    async loginToZulip(userDetils) {
      const config = {
        username: userDetils.userName,
        password: userDetils.password,
        realm: URLS.HOST_URL.ZULIP_REALM,
      };
      const zulip = await zulipInit(config);
      console.log("zulip details : zulip", zulip);
      if (zulip.config) {
        //set detail to local storage
        let zdetails = {
          username: zulip.config.username,
          apiKey: zulip.config.apiKey,
        };
        this.$serviceHelpers.setDetails("zulip_details", zdetails);
      }
    },
    togglePassword() {
      let x = document.getElementById("password__field");
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },
    async onSubmit() {
      let authData = {
        password: this.password,
        userName: this.email,
      };
      if (this.isApiCalled) {
      } else {
        this.isApiCalled = true;
        sessionStorage.clear();
        // alert(device_id);
        this.device_id = this.$serviceHelpers.uuidv4();
        authData.id = this.device_id;
        try {
          let login = await api.AuthServices.auth_login(authData);
          if(login.status==200 && login.data.data){
              let apollodart=login.data.data;
              if(apollodart.user.user_roles.role_name=="Super Admin" || apollodart.user.user_roles.role_name=="Vendor Admin" ){
                console.log("roles",apollodart.user.user_roles.role_name);
              }else{
                apollodart.customers=[`${apollodart.user.entity.entity_name}_${apollodart.user.entity.sub_entity_name}`];
                login.data.data=apollodart;
              }
          }
          this.login_Response = login;
          this.isLoading = true;

          if (login.status == 200) {
            this.proceedToLogin(login.data);
          } else {
            localStorage.setItem("theme", "grey");
            this.errcode = login.data.message;
            this.isLoading = false;
          }
        } catch (error) {
          console.error("Error in login", error);
          this.$toast.error("Something went wrong", {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
        this.isApiCalled = false;
      }
    },
    async proceedToLogin(login) {
      try {
        if (login.agreement && login.agreement.length) {
          // this.$serviceHelpers.setDetails("apollodart", login.data);
          this.$serviceHelpers.setDetails("670023242:az", login.tokens);
          this.showDisclaimer = true;
        } else {
          this.loginToZulip({
            password: this.password,
            userName: this.email,
          });
          this.agreedAgreement(login);
          return true;
        }
      } catch (error) {
        return false;
      }
    },
    redirect: function (isSweetSpot) {
      window.location.replace(
        `${
          isSweetSpot
            ? URLS.HOST_URL.SWEET_SPOT
            : URLS.HOST_URL.ADMINISTRATOR_URL
        }auth/login?dfc364ca-86b9-5162-9726-79c48cf0d0c4=${JSON.stringify(
          this.$serviceHelpers.getDetails("y:a")
        )}&abcbf877-edea-5d5b-91c3-a5bba8374a10=${JSON.stringify(
          this.$serviceHelpers.getDetails("670023242:az")
        )}`
      );
    },
    async onTokenLogin(accessToken, refreshToken, customer, scope) {
      var authData = {
        accessToken,
        refreshToken,
        customer,
        scope,
      };
      try {
        let login = await api.AuthServices.tokenLogin(authData);
        if (login.status == 200) {
          this.$router.push("/app");
          this.$toast.success("Welcome to Apollo", {
            position: "top",
            duration: 1000,
          });
        } else {
          this.errcode = login.data.message;
          this.$router.push("/auth/login");
        }
      } catch (error) {
        this.$router.push("/auth/login");
      }
    },
    getMessage: function () {
      return this.message;
    },
    toggleEye() {
      this.pass_showHide = !this.pass_showHide;
    },
    disagreeDisclaimer() {
      localStorage.clear();
      sessionStorage.clear();
      this.isLoading = false;
      this.showDisclaimer = false;
      this.errcode = "";
      this.password = "";
      this.email = "";
      // this.isAgree = false;
    },
    agreedAgreement(login) {
      this.$serviceHelpers.setDetails("apollodart", login.data);
      this.$serviceHelpers.setDetails("y:a", { device_id: this.device_id });
      this.$serviceHelpers.setDetails("670023242:az", login.tokens);

      this.$toast.success(login.message, { position: "top" });
      this.isLoading = false;
      if (this.isRedirectUserManagement)
        setTimeout(() => {
          this.redirect();
        }, 200);
      if (this.isRedirectSST)
        setTimeout(() => {
          this.redirect(true);
        }, 200);
      else this.$router.push("/app");
      return true;
    },
  },
  computed: {
    selectedThemeName() {
      return this.$store.state.rect.darkmode;
    },
    logoURL() {
      if (
        this.$store.state.data.isVendor ||
        this.$store.state.data?.themeDetails?.vendorDetails?.logo
      ) {
        // this.toUpdateElement++;
        return this.$store.state.data?.themeDetails?.vendorDetails?.logo;
      } else {
        if (this.selectedThemeName !== "white") {
          return (
            this.$store.state.data?.themeDetails?.defaultDarkThemeLogo ||
            darklogoImage
          );
        } else {
          return (
            this.$store.state.data?.themeDetails?.defaultLightThemeLogo ||
            lightlogoImage
          );
        }
      }
    },
  },
  async beforeMount() {
    const route = useRoute();
    console.log("login path", route.fullPath);
    console.log("is full logout ", route.query.logout);
    this.isRedirectUserManagement = route.fullPath.indexOf("cb-usm") > -1;
    this.isRedirectSST = route.fullPath.indexOf("cb-sst") > -1;
  },
};
</script>
<style scoped>
.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) #479669;
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.bg_login {
  /* background: #0f1319; */
  background: var(--navBar2Bgsame);
  color: var(--textColor);
}

.login_logo {
  /* width: 100px; */
  width: 55%;
  margin: 0 auto;
  /* margin-bottom: 15px; */
  margin-bottom: 0px;
}

.button {
  background: #5aba81;
}

.sign_in_card {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.curser {
  cursor: pointer;
}

.show_hide {
  position: absolute;
  right: 22px;
  top: 40px;
  cursor: pointer;
  color: var(--textColor);
}

.ipField {
  border-color: var(--activeTextColor);
}

.eye__icon__span {
  cursor: pointer;
  position: absolute;
  margin-left: -25px;
  margin-top: 13px;
}

.eye__icon {
  color: rgb(0, 0, 0);
}

input.checkbox_agree {
  width: 15px;
  height: 15px;
  top: 0px;
}

[type="checkbox"] {
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
</style>
