import Axios from "axios";
import constant from "../constant";
import api from "../dictionary/index";

export default {
  getWells: async (data) => {
    let response = await Axios.get(constant.BASE_URL + api.well.GET_WELLS(), {
      params: data,
    });
    return response;
  },
  getWellDetails: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_WELL_DETAILS(),
      {
        params: data,
      }
    );
    return response;
  },
  getWellDepthLogDetails: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_DEPTH_LOG_DETAILS(),
      {
        params: {
          every: data.every,
          from: data.from,
          to: data.to,
          wellbore_id: "us_26972838_wb1", //data.wellbor_id
          fields: data.fields,
          offset_field: data.offset_field,
          well_id: "us_26972838", //data.well_id
        },
      }
    );
    return response;
  },
  getCountOfWellboreDepthLogs: async (data) => {
    //
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_COUNT_WELLBORE_DEPTH_LOGS(),
      {
        params: {
          wellbore_id: "us_26972838_wb1", //data.wellbor_id
          well_id: "us_26972838", //data.well_id
        },
      }
    );
    return response;
  },
  getColumnsOfDepthLog: async () => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_COLUMNS_DEPTH_LOG_TABLE()
    );
    return response;
  },
  getMinMaxInfoWithFilter: async (data) => {
    //
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_MIN_MAX_INFO(),
      {
        params: {
          wellbore_id: "us_26972838_wb1", //data.wellbor_id
          from: 10,
          to: 100,
        },
      }
    );
    return response;
  },
  getDataForTags: async (data) => {
    //
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_DATA_FOR(),
      {
        params: {
          fields: data.fields,
        },
      }
    );
    return response;
  },
  getLogType: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_LOG_TYPE(data)
    );
    return response;
  },

  // Cassandra Apis
  getWellData: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_WELL_DATA(data)
    );
    return response;
  },
  getDepthTags: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_DEPTH_TAGS(),
      {
        params: data,
      }
    );
    return response;
  },
  getTimeTags: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_TIME_TAGS(data)
    );
    return response;
  },
  getDepthLogs: async (data) => {
    let response = await Axios.post(
      constant.BASE_URL + api.well.GET_DEPTH_LOGS(),
      data
    );
    return response;
  },
  getDephtLogsSingle: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_DEPTH_LOGS_SINGLE(),
      {
        params: data,
      }
    );
    return response;
  },
  getSingleValueLog: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_LOG_AT_DEPTH(),
      {
        params: data,
      }
    );
    return response;
  },
  getSingleValueTimeLog: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_LOG_AT_TIME(),
      {
        params: data,
      }
    );
    return response;
  },
  getMinMaxDepth: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_MIN_MAX_DEPTH(),
      {
        params: data,
      }
    );
    return response;
  },
  getMinMaxTime: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_MIN_MAX_TIME(),
      {
        params: data,
      }
    );
    return response;
  },
  getDephtLogs: async (data) => {
    let response = await Axios.post(
      constant.BASE_URL + api.well.GET_DEPTH_LOGS(),
      data
    );
    return response;
  },
  getTimeLogs: async (data) => {
    let response = await Axios.post(
      constant.BASE_URL + api.well.GET_TIME_LOGS(),
      data
    );
    return response;
  },
  getRigActivityData: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_RIG_ACTIVITY(),
      {
        params: data,
      }
    );
    return response;
  },
  getDataTableData: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_DATATABLE_DATA(),
      {
        params: data,
      }
    );
    return response;
  },
  getWellInformation: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_WELL_INFORMATION(),
      {
        params: data,
      }
    );
    return response;
  },
  getWellsInformation: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_TORQUE_AND_DRAG_WELLS_INFO(),
      {
        params: data,
      }
    );
    return response;
  },
  getPlanActualData: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_TRAJECTORY_PLAN_ACTUAL_DATA(),
      {
        params: data,
      }
    );
    return response;
  },
  getDirectionForTrajectory: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_DIRECTIONAL_TRAJECTORY_DATA(),
      {
        params: data,
      }
    );
    return response;
  },
  getTorqueAndDragData: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_TORQUE_AND_DRAG_DATA(),
      {
        params: data,
      }
    );
    return response;
  },
  getTorqueAndDragDataTime: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_TORQUE_AND_DRAG_DATA_TIME(),
      {
        params: data,
      }
    );
    return response;
  },
  getTorqueAndDragWellInfo: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_TORQUE_AND_DRAG_WELL_INFO(),
      {
        params: data,
      }
    );
    return response;
  },
  getTorqueAndDragPlannedData: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_TORQUE_AND_DRAG_PLANNED_DATA(),
      {
        params: data,
      }
    );
    return response;
  },
  saveBha: async (data) => {
    try {
      let response = await Axios.post(
        constant.BASE_URL + api.well.SAVE_BHA(),
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  },
  getSavedBhaRuns: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_SAVE_BHA(),
      {
        params: data,
      }
    );
    return response;
  },
  getWellFormations: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_WELL_FORMATION(),
      {
        params: data,
      }
    );
    return response;
  },
  getWellGeometry: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_WELL_GEOMETRY(),
      {
        params: data,
      }
    );
    return response;
  },
  getOptimizations: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_OPTIMIZATIONS(),
      {
        params: data,
      }
    );
    return response;
  },
  getWellSections: async (data) => {
    let response = await Axios.post(
      constant.BASE_URL + api.well.GET_WELL_SECTIONS(),
      data
    );
    return response;
  },
  clearStorage: async (data) => {
    let response = await Axios.post(
      constant.BASE_URL + api.well.CLEAR_CACHE(),
      data
    );
    return response;
  },
  getUnitConversionFactorDetails: async (data) => {
    let response = await Axios.get(
      constant.BASE_URL + api.well.GET_UNIT_CONVERSION_FACTORS_DETAILS()
    );
    return response;
  },
  deleteLokiCacheDetails: async (
    display_name,
    well_id,
    wellbore_name,
    delte_only = ""
  ) => {
    let url = constant.BASE_URL + api.well.DELETE_SERVER_CACHE();
    let payload = {
      display_name,
      well_id,
      wellbore_name,
    };
    if (delte_only != "") {
      payload.delte_only = delte_only;
    }

    let response = await Axios.get(url, { params: payload });
    return response;
  },
  boosterPumpSearch: async (payload) => {
    let response = Axios.post(
      constant.DATASERVICES_URL + api.well.BOOSTER_PUMP_SEARCH(),
      payload
    );
    return response;
  },
  boosterPumpSave: async (payload) => {
    let response = Axios.post(
      constant.DATASERVICES_URL + api.well.BOOSTER_PUMP_SAVE(),
      payload
    );
    return response;
  },
};
