<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import api from "./api/services";
import { useRoute } from "vue-router";
import darklogoImage from "./assets/img/apollodart_logo_white.png";
import lightlogoImage from "./assets/img/apollodart_logo_black.png";
// import darklogoImage from "./assets/img/drillinktech_logo.png";
// import lightlogoImage from "./assets/img/drillinktech_logo.png";
import helperServices from "./helper-services";
import LZString from "lz-string";
import { useFavicon } from "@vueuse/core";
import axios from "axios";
import config from "./config";

// import FooterAdmin from "./components/Footers/FooterAdmin.vue";
export default {
  components: {},
  data() {
    return {
      isAuth: true,
      isAuth: true,
      deferredPrompt: null,
      close: true,
      title: "Apollodart",
      title: "Apollodart",
      isLoading: false,
      installBtn: "none",
      installer: undefined,
      darkDark: "grey",
      root: null,
      defaultLightThemeJson: {
        apNavbarBg1: "#FFFFF7",
        apNavbarBg2: "#D0D5E3",
        apSidebarBg: "#F5F5F5",
        apActiveColor: "#073099",
        apBoxBg: "#EEF1F5",
        apBoxTextColor: "#3C455C",
        apDefaultColor: "#6C7593",
        apBoxPopUpBg: "#fff",
        apTextColor: "#000000",
        apSideBarButtonBg: "#2a2a2a",
        apPopUpBg: "rgba(70, 69, 69, 0.86)",
        apSliderBg: "#B4B5BA",
        apCentralBg: "#ffffffe6",
        apNavHeadBg: "#A9A9A9",
        apBoxHeadBg: "#fdffe5",
        apDepthTimeTextBg: "#3f3f3f",
        apGridBg: "#E5E5E5",
        apDefaultWidgetBg: "#d8d8ed",
        apBs1: "#ffffff",
        apBs2: "#c1c2c5",
      },
      defaultGreyThemeJson: {
        apNavbarBg1: "#3f3f3f",
        apNavbarBg2: "#2a2a2a",
        apSidebarBg: "#3f3f3f",
        apActiveColor: "#63CDFF",
        apBoxBg: "#2a2a2a",
        apBoxTextColor: "#cfd6e1",
        apDefaultColor: "#B4B5BA",
        apBoxPopUpBg: "#414244",
        apTextColor: "#ffffff",
        apSideBarButtonBg: "#2a2a2a",
        apPopUpBg: "rgba(142, 137, 137, 0.86)",
        apSliderBg: "#B4B5BA",
        apCentralBg: "#2a2a2a",
        apNavHeadBg: "#A9A9A9",
        apBoxHeadBg: "#1e293b",
        apDepthTimeTextBg: "#414244",
        apGridBg: "#414244",
        apDefaultWidgetBg: "#919193",
        apBs1: "#3c4049",
        apBs2: "#202227",
      },
      defaultBlueThemeJson: {
        apNavbarBg1: "#1e293b",
        apNavbarBg2: "#0f172a",
        apSidebarBg: "#334155",
        apActiveColor: "#63CDFF",
        apBoxBg: "#334155",
        apBoxTextColor: "#ffffff",
        apDefaultColor: "#B4B5BA",
        apBoxPopUpBg: "#414244",
        apTextColor: "#ffffff",
        apSideBarButtonBg: "#0f172a",
        apPopUpBg: "rgba(142, 137, 137, 0.86)",
        apSliderBg: "#B4B5BA",
        apCentralBg: "#0f172a",
        apNavHeadBg: "#A9A9A9",
        apBoxHeadBg: "#1e293b",
        apDepthTimeTextBg: "#414244",
        apGridBg: "#414244",
        apDefaultWidgetBg: "#919193",
        apBs1: "#3c4049",
        apBs2: "#202227",
      },
      isVendorFound: false,
      vendorThemeJson: {},
    };
  },
  watch: {
    darkDark: {
      handler: function () {
        const theme = document.documentElement;
        let data = this.defaultGreyThemeJson;
        if (this.$store.state.data.isVendor && !this.is_super_admin) {
          data = this.vendorThemeJson;
        } else {
          if (this.darkDark === "white") {
            data = this.defaultLightThemeJson;
          } else if (this.darkDark === "grey") {
            data = this.defaultGreyThemeJson;
          } else if (this.darkDark === "blue") {
            data = this.defaultBlueThemeJson;
          }
        }

        // if(this.$store.state.data?.themeDetails?.vendorDetails) data = this.$store.state.data.themeDetails.vendorDetails.miscellaneous
            if(this.$store.state.data?.themeDetails?.vendorDetails){
              let {themeConfiguration: themeData} = this.$store.state.data?.themeDetails?.vendorDetails 
              data = {
                ...data,
                apNavbarBg2: themeData.primaryBackground, //"#D0D5E3", //primaryBackground
                apTextColor: themeData.primaryColor, //"#000000", //primaryColor
                apActiveColor: themeData.activeColor, //"#073099", //activeColor
                apBoxBg: themeData.secondaryBackground, //"#EEF1F5", //secondaryBackground,
                apCentralBg: themeData.primaryBackground,
              }
            }
            for (let key in data) {
              // theme.style.setProperty(key, themeData[key]);
              const value = data[key];

              this.applyThemeColors(key, value, theme);
              theme.style.setProperty("--liveBtnGreen", "#12B822");
              theme.style.setProperty(
                "--transition",
                "background 3s ease, color 0.5s ease"
              );

              // console.log(key, themeData[key]);
            }
            theme.style.setProperty("--lwdGraphBg", "#ffffff");

        // if (this.darkDark === "white") {
        //   this.root.style.setProperty("--textColorTooltip", "#fff");
        //   this.root.style.setProperty("--defaultBorderColorForWidgets", "#000");
        //   this.root.style.setProperty("--verticalBarScaleRectBg", "#f5f5f5");
        //   this.root.style.setProperty(
        //     "--transition",
        //     "background 3s ease, color 0.5s ease"
        //   );
        //   this.root.style.setProperty("--popupBg", "rgba(70, 69, 69, 0.86)");
        //   this.root.style.setProperty("--fontWeight", 600);
        //   this.root.style.setProperty("--central_bg", "#ffffffe6");
        //   this.root.style.setProperty("--nav1HdngClr", "#073099");
        //   this.root.style.setProperty("--nav1HdngClrsame", "#A9A9A9");
        //   this.root.style.setProperty("--nav1BgClr", "#000000");
        //   this.root.style.setProperty("--nav1BgClrsame", "#D0D5E3");
        //   this.root.style.setProperty("--root_nav", "#F5F5F5");
        //   this.root.style.setProperty("--navBar2Bg", "#FFFFF7");
        //   this.root.style.setProperty("--navBar2Bgsame", "#eef1f5");
        //   this.root.style.setProperty("--widgetBg", "#FFFFF7");
        //   this.root.style.setProperty("--textColor", "#000000");
        //   this.root.style.setProperty("--activeTextColor", "#073099");
        //   this.root.style.setProperty("--sidebarbg", "#fff");
        //   this.root.style.setProperty("--sidebarMnList", "#2a2a2a");
        //   this.root.style.setProperty("--sidebarButtonbg", "#2a2a2a");
        //   this.root.style.setProperty("--headingColor", "#073099");
        //   this.root.style.setProperty("--sidebarListBgColor", "#F5F5F5");
        //   this.root.style.setProperty("--sidebarListColor", "#073099");
        //   this.root.style.setProperty("--colorBoxBg", "#EEF1F5");
        //   this.root.style.setProperty("--colorBoxHeadingText", "#000000");
        //   this.root.style.setProperty("--colorBoxHeadBackround", "#fdffe5");
        //   this.root.style.setProperty("--colorBoxWhiteNum", "#000000");
        //   this.root.style.setProperty("--colorBoxPopUpBg", "#fff");
        //   this.root.style.setProperty("--colorBoxPopUpHeadingColor", "#3C455C");
        //   this.root.style.setProperty("--colorBoxPopUpInputBg", "#EEF1F5");
        //   this.root.style.setProperty("--dp-background-color", "#EEF1F5");
        //   this.root.style.setProperty("--colorBoxPopUpDeleteBg", "#B4B5BA");
        //   this.root.style.setProperty("--colorBoxPopUpSaveBg", "#073099");
        //   this.root.style.setProperty("--colorBoxPopUpDeleteText", "#fff");
        //   this.root.style.setProperty("--dropDownBg", "#EEF1F5");
        //   this.root.style.setProperty("--searchBarBg", "#EEF1F5");
        //   this.root.style.setProperty("--rightTwobars", "#3C455C");
        //   this.root.style.setProperty("--deleteDragRizeWindowBg", "#2a2a2a");
        //   this.root.style.setProperty(
        //     "--deleteDragRizeWindowBgColor",
        //     "#F5F5F5"
        //   );
        //   this.root.style.setProperty("--selectionWellPopupBg", "#fff");
        //   this.root.style.setProperty("--selectingTimeFeetColor", "#3C455C");
        //   this.root.style.setProperty("--selectingTimeFeetColorBg", "#3f3f3f");
        //   this.root.style.setProperty(
        //     "--selectingTimeFeetColorBgActive",
        //     "#073099"
        //   );
        //   this.root.style.setProperty("--botoomMinimaizerBodyBg", "#D0D5E3");
        //   this.root.style.setProperty("--gridLinesColor", "#E5E5E5");
        //   this.root.style.setProperty("--TandDCorrelationLeftBarBg", "#fff");
        //   this.root.style.setProperty("--toggleSliderBg", "#B4B5BA");
        //   this.root.style.setProperty("--liveBtnGreen", "#12B822");
        //   this.root.style.setProperty("--defalutHamburger", "#6C7593");
        //   this.root.style.setProperty("--activeHamburger", "#073099");
        //   this.root.style.setProperty("--searchColor", "#6C7593");
        //   this.root.style.setProperty("--rightBarSelecteleBg", "#F5F5F5");
        //   this.root.style.setProperty("--dataTableOddRowBg", "#FFFFF7");
        //   this.root.style.setProperty("--dataTableEvenRowBg", "#f5f5f5");
        //   this.root.style.setProperty("--logoImage", lightlogoImage);
        //   this.root.style.setProperty("--defaultBgOfBgWidget", "#d8d8ed");
        //   this.root.style.setProperty("--defaultBgOfRadialWidget", "#fff");
        //   this.root.style.setProperty("--activeCustomerBg", "#d0d5e3");
        //   this.root.style.setProperty("--lwdGraphBg", "#fff");
        //   this.root.style.setProperty("--activeFeetBtnText", "#fff");
        //   this.root.style.setProperty("--bs1", "#ffffff");
        //   this.root.style.setProperty("--bs2", "#c1c2c5");
        // } else if (this.darkDark === "grey") {
        //   this.root.style.setProperty("--textColorTooltip", "#000");
        //   this.root.style.setProperty("--defaultBorderColorForWidgets", "#fff");
        //   this.root.style.setProperty("--verticalBarScaleRectBg", "#fff");
        //   this.root.style.setProperty("--popupBg", "rgba(142, 137, 137, 0.86)");
        //   this.root.style.setProperty("--fontWeight", 300);
        //   this.root.style.setProperty("--central_bg", "#2a2a2a");
        //   this.root.style.setProperty("--nav1HdngClr", "#A9A9A9");
        //   this.root.style.setProperty("--nav1HdngClrsame", "#A9A9A9");
        //   this.root.style.setProperty("--activeTextColor", "#63CDFF");
        //   this.root.style.setProperty("--nav1BgClr", "#2a2a2a");
        //   this.root.style.setProperty("--nav1BgClrsame", "#2a2a2a");
        //   this.root.style.setProperty("--root_nav", "#545454");
        //   this.root.style.setProperty("--navBar2Bg", "#3f3f3f");
        //   this.root.style.setProperty("--navBar2Bgsame", "#3f3f3f");
        //   this.root.style.setProperty("--widgetBg", "#3f3f3f");
        //   this.root.style.setProperty("--textColor", "#ffffff");
        //   this.root.style.setProperty("--sidebarbg", "#3f3f3f");
        //   this.root.style.setProperty("--sidebarMnList", "#3f3f3f");
        //   this.root.style.setProperty("--sidebarButtonbg", "#2a2a2a");
        //   this.root.style.setProperty("--headingColor", "#B4B5BA");
        //   this.root.style.setProperty("--sidebarListBgColor", "#2a2a2a");
        //   this.root.style.setProperty("--sidebarListColor", "#c3c3c3");
        //   this.root.style.setProperty("--colorBoxBg", "#2a2a2a");
        //   this.root.style.setProperty("--colorBoxHeadingText", "#cfd6e1");
        //   this.root.style.setProperty("--colorBoxHeadBackround", "#1e293b");
        //   this.root.style.setProperty("--colorBoxWhiteNum", "#ffffff");
        //   this.root.style.setProperty("--colorBoxPopUpBg", "#414244");
        //   this.root.style.setProperty("--colorBoxPopUpHeadingColor", "#fff");
        //   this.root.style.setProperty("--colorBoxPopUpInputBg", "#2a2a2a");
        //   this.root.style.setProperty("--dp-background-color", "#2a2a2a");
        //   this.root.style.setProperty("--colorBoxPopUpDeleteBg", "#393939");
        //   this.root.style.setProperty("--colorBoxPopUpSaveBg", "#555");
        //   this.root.style.setProperty("--colorBoxPopUpDeleteText", "#B4B8BE");
        //   this.root.style.setProperty("--dropDownBg", "#2a2a2a");
        //   this.root.style.setProperty("--searchBarBg", "#000");
        //   this.root.style.setProperty("--rightTwobars", "#777");
        //   this.root.style.setProperty("--deleteDragRizeWindowBg", "#E5E5E5");
        //   this.root.style.setProperty(
        //     "--deleteDragRizeWindowBgColor",
        //     "#2a2a2a"
        //   );
        //   this.root.style.setProperty("--selectionWellPopupBg", "#2a2a2a");
        //   this.root.style.setProperty("--selectingTimeFeetColor", "#B4B8BE");
        //   this.root.style.setProperty("--selectingTimeFeetColorBg", "#414244");
        //   this.root.style.setProperty(
        //     "--selectingTimeFeetColorBgActive",
        //     "#63CDFF"
        //   );
        //   this.root.style.setProperty("--botoomMinimaizerBodyBg", "#303030");
        //   this.root.style.setProperty("--gridLinesColor", "#414244");
        //   this.root.style.setProperty("--TandDCorrelationLeftBarBg", "#383838");
        //   this.root.style.setProperty("--toggleSliderBg", "#B4B5BA");
        //   this.root.style.setProperty("--liveBtnGreen", "#12B822");
        //   this.root.style.setProperty("--defalutHamburger", "#B4B5BA");
        //   this.root.style.setProperty("--activeHamburger", "#fff");
        //   this.root.style.setProperty("--searchColor", "#dad7d7");
        //   this.root.style.setProperty("--rightBarSelecteleBg", "#4A4E57");
        //   this.root.style.setProperty("--dataTableOddRowBg", "#1e293b");
        //   this.root.style.setProperty("--dataTableEvenRowBg", "#3f3f3f");
        //   this.root.style.setProperty("--logoImage", darklogoImage);
        //   this.root.style.setProperty("--defaultBgOfBgWidget", "#919193");
        //   this.root.style.setProperty("--defaultBgOfRadialWidget", "#19181b");
        //   this.root.style.setProperty("--activeCustomerBg", "#3f3f3f");
        //   this.root.style.setProperty("--lwdGraphBg", "#fff");
        //   this.root.style.setProperty("--activeFeetBtnText", "#fff");
        //   this.root.style.setProperty("--bs1", "#3c4049");
        //   this.root.style.setProperty("--bs2", "#202227");
        //   // selectingTimeFeetColorBg
        //   // selectingTimeFeetColorBgActive
        //   // TandDCorrelationLeftBarBg
        //   // toggleSliderBg
        // } else if (this.darkDark === "blue") {
        //   this.root.style.setProperty("--textColorTooltip", "#000");
        //   this.root.style.setProperty("--defaultBorderColorForWidgets", "#fff");
        //   this.root.style.setProperty("--verticalBarScaleRectBg", "#fff");
        //   this.root.style.setProperty("--popupBg", "rgba(142, 137, 137, 0.86)");
        //   this.root.style.setProperty("--inactivePopBg", "#fff");
        //   this.root.style.setProperty("--lwdGraphBg", "#fff");
        //   this.root.style.setProperty("--fontWeight", 300);
        //   this.root.style.setProperty("--central_bg", "#0f172a");
        //   this.root.style.setProperty("--nav1HdngClr", "#A9A9A9");
        //   this.root.style.setProperty("--nav1HdngClrsame", "#A9A9A9");
        //   this.root.style.setProperty("--activeTextColor", "#63CDFF");
        //   this.root.style.setProperty("--nav1BgClr", "#0f172a");
        //   this.root.style.setProperty("--nav1BgClrsame", "#0f172a");
        //   this.root.style.setProperty("--root_nav", "#334155");
        //   this.root.style.setProperty("--navBar2Bg", "#1e293b");
        //   this.root.style.setProperty("--navBar2Bgsame", "#1e293b");
        //   this.root.style.setProperty("--widgetBg", "#1e293b");
        //   this.root.style.setProperty("--textColor", "#ffffff");
        //   this.root.style.setProperty("--sidebarbg", "#334155");
        //   this.root.style.setProperty("--sidebarMnList", "#334155");
        //   this.root.style.setProperty("--sidebarButtonbg", "#0f172a");
        //   this.root.style.setProperty("--headingColor", "#B4B5BA");
        //   this.root.style.setProperty("--sidebarListBgColor", "#0f172a");
        //   this.root.style.setProperty("--sidebarListColor", "#c3c3c3");
        //   this.root.style.setProperty("--colorBoxBg", "#334155");
        //   this.root.style.setProperty("--colorBoxHeadingText", "#ffffff");
        //   this.root.style.setProperty("--colorBoxHeadBackround", "#1e293b");
        //   this.root.style.setProperty("--colorBoxWhiteNum", "#ffffff");
        //   this.root.style.setProperty("--colorBoxPopUpBg", "#414244");
        //   this.root.style.setProperty("--colorBoxPopUpHeadingColor", "#fff");
        //   this.root.style.setProperty("--colorBoxPopUpInputBg", "#0f172a");
        //   this.root.style.setProperty("--dp-background-color", "#0f172a");
        //   this.root.style.setProperty("--colorBoxPopUpDeleteBg", "#393939");
        //   this.root.style.setProperty("--colorBoxPopUpSaveBg", "#555");
        //   this.root.style.setProperty("--colorBoxPopUpDeleteText", "#B4B8BE");
        //   this.root.style.setProperty("--dropDownBg", "#0f172a");
        //   this.root.style.setProperty("--searchBarBg", "#000");
        //   this.root.style.setProperty("--rightTwobars", "#777");
        //   this.root.style.setProperty("--deleteDragRizeWindowBg", "#E5E5E5");
        //   this.root.style.setProperty(
        //     "--deleteDragRizeWindowBgColor",
        //     "#0f172a"
        //   );
        //   this.root.style.setProperty("--selectionWellPopupBg", "#0f172a");
        //   this.root.style.setProperty("--selectingTimeFeetColor", "#B4B8BE");
        //   this.root.style.setProperty("--selectingTimeFeetColorBg", "#414244");
        //   this.root.style.setProperty(
        //     "--selectingTimeFeetColorBgActive",
        //     "#0f172a"
        //   );
        //   this.root.style.setProperty("--botoomMinimaizerBodyBg", "#303030");
        //   this.root.style.setProperty("--gridLinesColor", "#414244");
        //   this.root.style.setProperty("--TandDCorrelationLeftBarBg", "#383838");
        //   this.root.style.setProperty("--toggleSliderBg", "#B4B5BA");
        //   this.root.style.setProperty("--liveBtnGreen", "#12B822");
        //   this.root.style.setProperty("--defalutHamburger", "#B4B5BA");
        //   this.root.style.setProperty("--activeHamburger", "#fff");
        //   this.root.style.setProperty("--searchColor", "#B4B5BA");
        //   this.root.style.setProperty("--rightBarSelecteleBg", "#4A4E57");
        //   this.root.style.setProperty("--dataTableOddRowBg", "#1e293b");
        //   this.root.style.setProperty("--dataTableEvenRowBg", "#33415");
        //   this.root.style.setProperty("--defaultBgOfBgWidget", "#919193");
        //   this.root.style.setProperty("--defaultBgOfRadialWidget", "#19181b");
        //   this.root.style.setProperty("--activeCustomerBg", "#1e293b");
        //   this.root.style.setProperty("--dataTableEvenRowBg", "#334155");
        //   this.root.style.setProperty("--inactivePopBg", "#0f172a");
        //   this.root.style.setProperty("--activeFeetBtnText", "#fff");

        //   this.root.style.setProperty("--bs1", "#3c4049");
        //   this.root.style.setProperty("--bs2", "#202227");

        //   // selectingTimeFeetColorBg
        //   // selectingTimeFeetColorBgActive
        //   // TandDCorrelationLeftBarBg
        //   // toggleSliderBg
        // }
      },
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.fullPath.includes("auth") ||
        this.$route.fullPath.includes("support")
        ? false
        : true;
    },
    is_super_admin() {
      return this.$store.state.data.is_super_admin;
    },
  },
  created() {
    // window.onbeforeunload = function(){
    //        return "handle your events or msgs here";
    // }
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      this.installBtn = "block";
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
    this.installer = () => {
      this.installBtn = "none";
      installPrompt.prompt();
      installPrompt.userChoise.then((result) => {
        if (result.outcome === "accepted") {
        } else {
        }
        installPrompt = null;
      });
    };
  },
  methods: {
    applyThemeColors(key, value, theme) {
      switch (key) {
        case "apNavbarBg1":
          theme.style.setProperty("--dataTableOddRowBg", value);
          theme.style.setProperty("--widgetBg", value);
          theme.style.setProperty("--navBar2Bg", value);
          theme.style.setProperty("--navBar2Bgsame", value);
          break;

        case "apNavbarBg2":
          theme.style.setProperty("--activeCustomerBg", value);
          theme.style.setProperty("--botoomMinimaizerBodyBg", value);
          theme.style.setProperty("--nav1BgClrsame", value);
          theme.style.setProperty("--sidebarListBgColor", value);
          theme.style.setProperty("--colorBoxPopUpInputBg", value);
          break;

        case "apSidebarBg":
          theme.style.setProperty("--verticalBarScaleRectBg", value);
          theme.style.setProperty("--root_nav", value);
          // theme.style.setProperty("--sidebarListBgColor", value);
          theme.style.setProperty("--deleteDragRizeWindowBgColor", value);
          theme.style.setProperty("--rightBarSelecteleBg", value);
          theme.style.setProperty("--dataTableEvenRowBg", value);
          theme.style.setProperty("--colorBoxBg", value);
          if(this.darkDark != 'white')
          theme.style.setProperty("--sidebarbg", value);
          break;

        case "apActiveColor":
          theme.style.setProperty("--nav1HdngClr", value);
          theme.style.setProperty("--activeHamburger", value);
          theme.style.setProperty("--activeTextColor", value);
          theme.style.setProperty("--headingColor", value);
          theme.style.setProperty("--sidebarListColor", value);
          theme.style.setProperty("--colorBoxPopUpSaveBg", value);
          theme.style.setProperty("--selectingTimeFeetColorBgActive", value);
          break;

        case "apBoxBg":
          // theme.style.setProperty("--colorBoxBg", value);
          // theme.style.setProperty("--colorBoxPopUpInputBg", value);
          theme.style.setProperty("--dp-background-color", value);
          theme.style.setProperty("--dropDownBg", value);
          theme.style.setProperty("--searchBarBg", value);
          theme.style.setProperty("--textColorTooltip", value);
          // theme.style.setProperty("--navBar2Bgsame", value);

          break;

        case "apBoxTextColor":
          theme.style.setProperty("--colorBoxPopUpHeadingColor", value);
          theme.style.setProperty("--rightTwobars", value);
          theme.style.setProperty("--selectingTimeFeetColor", value);
          break;

        case "apDefaultColor":
          theme.style.setProperty("--defalutHamburger", value);
          theme.style.setProperty("--searchColor", value);
          break;

        case "apBoxPopUpBg":
          theme.style.setProperty("--colorBoxPopUpBg", value);
          theme.style.setProperty("--selectionWellPopupBg", value);
          theme.style.setProperty("--TandDCorrelationLeftBarBg", value);
          theme.style.setProperty("--defaultBgOfRadialWidget", value);
          // theme.style.setProperty("--lwdGraphBg", value);
          theme.style.setProperty("--activeFeetBtnText", value);
          if(this.darkDark == 'white')
          theme.style.setProperty("--sidebarbg", value);
          break;

        case "apTextColor":
          theme.style.setProperty("--textColor", value);
          theme.style.setProperty("--colorBoxHeadingText", value);
          theme.style.setProperty("--colorBoxWhiteNum", value);
          theme.style.setProperty("--defaultBorderColorForWidgets", value);

          break;

        case "apSideBarButtonBg":
          theme.style.setProperty("--sidebarMnList", value);
          theme.style.setProperty("--sidebarButtonbg", value);
          theme.style.setProperty("--deleteDragRizeWindowBg", value);
          break;

        case "apPopUpBg":
          theme.style.setProperty("--popupBg", value);
          break;

        case "apSliderBg":
          theme.style.setProperty("--toggleSliderBg", value);
          break;

        case "apCentralBg":
          theme.style.setProperty("--central_bg", value);
          break;

        case "apNavHeadBg":
          theme.style.setProperty("--nav1HdngClrsame", value);
          break;

        case "apBoxHeadBg":
          theme.style.setProperty("--colorBoxHeadBackround", value);
          break;

        case "apDepthTimeTextBg":
          theme.style.setProperty("--selectingTimeFeetColorBg", value);
          break;

        case "apGridBg":
          theme.style.setProperty("--gridLinesColor", value);
          break;

        case "apDefaultWidgetBg":
          theme.style.setProperty("--defaultBgOfBgWidget", value);
          break;

        case "apBs1":
          theme.style.setProperty("--bs1", value);
          break;

        case "apBs2":
          theme.style.setProperty("--bs2", value);
          break;

        default:
          theme.style.setProperty(key, value);
          break;
      }
    },
    handleVisibilityChange(event) {
      try {
        if (document.visibilityState === "hidden") {
          console.log(" well correlation persistance : ", this.$store.state);
          console.time(" saving session ");
          sessionStorage.clear();
          console.timeEnd(" saving session ");

          console.time(" saving session 1");
          let jsonString = JSON.parse(JSON.stringify(this.$store.state));
          // delete jsonString.data.wells;
          jsonString = JSON.stringify(jsonString);

          console.timeEnd(" saving session 1");

          console.time(" saving session 2");
          const compressedData = LZString.compress(jsonString);
          console.timeEnd(" saving session 2");

          console.time(" saving session 3");
          sessionStorage.setItem("vuex", compressedData);
          console.timeEnd(" saving session 3");
        }
      } catch (error) {
        console.error("error in  handle visibility changes: ", error);
      }
    },
    removeUser() {
      alert("Sure");
    },
    async onTokenLogin(accessToken, refreshToken, customer, scope) {
      var authData = {
        accessToken,
        refreshToken,
        customer,
        scope,
      };
      try {
        let login = await api.AuthServices.tokenLogin(authData);
        if (login.status == 200) {
          this.$router.push("/app");
          this.$toast.success("Welcome to Apollo", {
            position: "top",
            duration: 1000,
          });
          // window.location.replace("/app")
        } else {
          this.errcode = login.data;
          this.$router.push("/auth/login");
        }
      } catch (error) {
        this.$router.push("/auth/login");
      }
    },
    async dismiss() {
      this.deferredPrompt = null;
      this.close == false;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    async open() {
      this.deferredPrompt.prompt();
    },
    closeDownload() {
      this.close = !this.close;
    },
    getBaseColorNameByRange(hex) {
      // Convert HEX to RGB
      let r = parseInt(hex.slice(1, 3), 16) / 255;
      let g = parseInt(hex.slice(3, 5), 16) / 255;
      let b = parseInt(hex.slice(5, 7), 16) / 255;

      // Find min, max, and delta
      const max = Math.max(r, g, b);
      const min = Math.min(r, g, b);
      const delta = max - min;

      // Calculate Lightness
      const l = (max + min) / 2;

      // Calculate Saturation
      const s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

      // Detect White/Near-White (adjusted to include light colors)
      if (l > 0.85 && s < 0.3) {
        // Allow lighter colors like #d0d5e3 to fall into white category
        return "white";
      }
      console.log(
        " ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ s and l ~~~~~~~~~~~~~~~~~~~~~~~~~~ : ",
        s,
        l
      );
      // Detect Gray
      if (s < 0.1 && l <= 0.9) {
        return "gray";
      }

      // Calculate Hue
      let h = 0;
      if (delta !== 0) {
        if (max === r) {
          h = ((g - b) / delta + (g < b ? 6 : 0)) * 60;
        } else if (max === g) {
          h = ((b - r) / delta + 2) * 60;
        } else {
          h = ((r - g) / delta + 4) * 60;
        }
      }

      // Determine Base Color by Hue
      if ((h >= 0 && h < 15) || (h >= 345 && h <= 360)) return "red";
      if (h >= 15 && h < 45) return "orange";
      if (h >= 45 && h < 75) return "yellow";
      if (h >= 75 && h < 150) return "green";
      if (h >= 150 && h < 210) return "cyan";
      if (h >= 210 && h < 270) return "blue";
      if (h >= 270 && h < 330) return "purple";
      if (h >= 330 && h < 345) return "pink";

      return "unknown";
    },
    async getThemeConfigDetalis() {
      try {
        const res = await axios.post(
          `${config.HOST_URL.DATASERVICES_URL}vendor/search`,
          {
            vendorUrl:
              window.location.host + ""
              // "atv.adart.apl-local",
          }
        );

        if (res.status === 200 && res.data && res.data.length > 0) {
          this.$store.state.data["isVendor"] = true;
          const { themeConfiguration, favIcon } = res.data[0];
          try {
            let themeData = themeConfiguration;

            let theme_category = this.getBaseColorNameByRange(
              themeData.primaryBackground
            );
            let defaultTheme = this.defaultGreyThemeJson;
            if (theme_category === "white") {
              defaultTheme = this.defaultLightThemeJson;
            } else if (theme_category === "grey") {
              defaultTheme = this.defaultGreyThemeJson;
            } else if (theme_category === "blue") {
              defaultTheme = this.defaultBlueThemeJson;
            }

            themeData = {
              ...defaultTheme,
              // apNavbarBg1: themeData.primaryBackground,
              apNavbarBg2: themeData.primaryBackground, //"#D0D5E3", //primaryBackground
              apTextColor: themeData.primaryColor, //"#000000", //primaryColor
              apActiveColor: themeData.activeColor, //"#073099", //activeColor
              apBoxBg: themeData.secondaryBackground, //"#EEF1F5", //secondaryBackground,
              apCentralBg: themeData.primaryBackground,
            };

            const theme = document.documentElement;

            this.vendorThemeJson = themeData;

            for (let key in themeData) {
              // theme.style.setProperty(key, themeData[key]);
              const value = themeData[key];

              this.applyThemeColors(key, value, theme);
              theme.style.setProperty("--liveBtnGreen", "#12B822");
              theme.style.setProperty(
                "--transition",
                "background 3s ease, color 0.5s ease"
              );

              // console.log(key, themeData[key]);
            }
            theme.style.setProperty("--lwdGraphBg", "#ffffff");
            if (res.data[0]?.vendorName)
              document.title = res.data[0].vendorName;
            if (res.data[0]?.favIcon) {
              useFavicon(res.data[0].favIcon, {
                rel: "icon",
              });
            }
            if (!this.$store.state.data["themeDetails"]) {
              this.$store.state.data["themeDetails"] = {};
            }
            this.$store.state.data["themeDetails"].vendorDetails = res.data[0];

            setTimeout(() => {
              this.$store.dispatch("rect/toggleDarkMode", theme_category);
            }, 100);
          } catch (error) {
            console.log("theme parsin error:", error);
          }
        } else {
          this.$store.state.data["themeDetails"] = {};
          this.$store.state.data["isVendor"] = false;
        }
      } catch (error) {
        this.$store.state.data["isVendor"] = false;
        console.error(" error in theme config : ", error);
      }
    },
  },
  // watch: {
  // title() {
  //   document.title = 'Apollodart'
  // },
  // },
  async mounted() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
    document.title = "Apollodart";
    // document.title = "DrillinkTech";
    if (!this.$store.state.data.isVendor) {
      let themeSelection = localStorage.getItem("theme");
      if (!themeSelection) {
        localStorage.setItem("theme", "white");
        themeSelection = localStorage.getItem("theme");
      }
      this.$store.dispatch("rect/toggleDarkMode", themeSelection);
      this.root = document.querySelector(":root");
      if (this.darkDark === "grey") {
        this.root.style.setProperty("--textColor", "#ffffff");
      }
    }

    this.$store.subscribe((mutation, state) => {
      if (mutation.type == "rect/DARK_MODE") {
        this.darkDark = this.$store.state.rect.darkmode;
      } else if (mutation.type == "disp/setDisplay") {
        console.log(
          "set display well correlation persistance : ",
          this.$store.state
        );
        console.time("set display saving session ");
        sessionStorage.clear();
        console.timeEnd("set display saving session ");

        console.time("set display saving session 1");
        let jsonString = JSON.parse(JSON.stringify(this.$store.state));
        // delete jsonString.data.wells;
        jsonString = JSON.stringify(jsonString);

        console.timeEnd("set display saving session 1");

        console.time("set display saving session 2");
        const compressedData = LZString.compress(jsonString);
        console.timeEnd("set display saving session 2");

        console.time("set display saving session 3");
        sessionStorage.setItem("vuex", compressedData);
        console.timeEnd("set display saving session 3");
      }
    });

    // set theme dynamically

    if (!this.$store.state.data?.themeDetails?.vendorDetails) {
      localStorage.setItem("theme", "grey");
      this.$store.dispatch("rect/toggleDarkMode", "grey");
    }
  },
  beforeUnmount() {
    console.log("store data : before unmount : ", this.$store.state);
    // Remove the event listener when the component is about to be unmounted
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  },
  beforeMount() {
    try {
      this.getThemeConfigDetalis();
      const prevStore = sessionStorage.getItem("vuex");

      if (prevStore) {
        const decompressedData = LZString.decompress(prevStore);
        console.time("decompress time");
        // const decompressedData = prevStore;
        const tempDta = JSON.parse(decompressedData);
        this.$store.replaceState(tempDta);
        console.timeEnd("decompress time");
      }
    } catch (error) {
      console.error(error);
    }
  },
  setup() {
    // useFavicon("https://banner2.cleanpng.com/20180714/qtv/aav31u2wg.webp", {
    //   rel: 'icon',
    // })
  },
};
</script>
<style>
@font-face {
    font-family: 'Work Sans';
    src: url('./assets/font/Work_Sans/WorkSans-VariableFont_wght.ttf') format('woff2'),
         url('./assets//font/Work_Sans/WorkSans-Italic-VariableFont_wght.ttf') format('woff');
    font-weight: 400;
    font-style: normal;
}

#app {
  background: #04070e;
  box-sizing: border-box;
  width: 100%;
  /* font-family: "Poppins", sans-serif;
  font-family: "Work Sans", sans-serif;
  font-family: "Open Sans", sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
  font-family: "Work Sans", sans-serif;
  /* 'Ubuntu', sans-serif; */
  overflow: hidden;
}

* {
  /* font-family: "Open Sans", sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
  font-family: "Work Sans", sans-serif;
  /* 'Ubuntu', sans-serif; */
}

#color1 {
  -webkit-appearance: none;
  padding: 0;
  border: none;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

#color1::-webkit-color-swatch {
  border: none;
  border-radius: 10px;
  padding: 0;
}

#color1::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 10px;
  padding: 0;
}

@media only screen and (max-device-width: 667px) and (min-device-width: 320px) and (orientation: landscape) {
  #app {
    display: block;
  }
}

.close_button {
  width: 9%;
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 9px;
  padding: 1px;
}

.bg_install_btns {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  font-size: 9px;
}

.download_logo {
  width: 30%;
  margin: 10px auto;
}

/* root for colors */
:root {
  --textColorTooltip: #000000;
  --activeFeetBtnText: #fff;
  --defaultBorderColorForWidgets: #fff;
  --verticalBarScaleRectBg: #fff;
  --popupBg: rgba(142, 137, 137, 0.86);
  --activeCustomerBg: #3f3f3f;
  --nav1HdngClrsame: #a9a9a9;
  --navBar2Bgsame: #3f3f3f;
  --nav1BgClrsame: #2a2a2a;
  --navBar2Bg: #3f3f3f;
  --widgetBg: #3f3f3f;
  --nav1BgClr: #2a2a2a;
  --root_nav: #545454;
  --nav1HdngClr: #a9a9a9;
  --textColor: #ffffff;
  --activeTextColor: #63cdff;
  --headingColor: #b4b5ba;
  --menuListColor: #c3c3c3;
  /* --sidebarbg: #2a2a2a; */
  --sidebarbg: #3f3f3f;
  --sidebarMnList: #3f3f3f;
  --sidebarButtonbg: #2a2a2a;
  --sidebarListColor: #c3c3c3;
  --sidebarListBgColor: #2a2a2a;
  --colorBoxBg: #2a2a2a;
  --central_bg: #2a2a2a;
  --colorBoxHeadingText: #cfd6e1;
  --colorBoxHeadBackround: #1e293b;
  --colorBoxWhiteNum: #ffffff;
  --colorBoxPopUpBg: #414244;
  --colorBoxPopUpHeadingColor: #fff;
  --colorBoxPopUpInputBg: #2a2a2a;
  --dp-background-color: #2a2a2a;
  --colorBoxPopUpDeleteBg: #393939;
  --colorBoxPopUpSaveBg: #555555;
  --colorBoxPopUpDeleteText: #b4b8be;
  --dropDownBg: #2a2a2a;
  --numericWidget: #414244;
  --numericValue: #fff;

  --rightSideBarOptions: #b4b8be;
  --rightSideBarOptionsSvgPath: #727272;
  --rightSideBarCloseBtn: #b4b5ba;
  --searchBarBg: #000;
  --rightTwobars: #777;
  --sideBarPopUpBg: #e5e5e5;
  --sideBarPopUpColor: #2a2a2a;
  --deleteDragRizeWindowBg: #e5e5e5;
  --deleteDragRizeWindowBgColor: #2a2a2a;
  --selectionWellPopupBg: #2a2a2a;
  --selectingTimeFeetColor: #b4b8be;
  /* --selectingTimeFeetColorBg: #3f3f3f; */
  --selectingTimeFeetColorBg: #414244;
  --selectingTimeFeetColorBgActive: #63cdff;
  /* --botoomMinimaizerBodyBg: #2a2a2a; */
  --botoomMinimaizerBodyBg: #303030;
  --gridLinesColor: #414244;
  --TandDCorrelationLeftBarBg: #383838;
  --toggleSliderBg: #b4b5ba;
  /* --liveBtnGreen:#32ff46; */
  --liveBtnGreen: #12b822;
  --defalutHamburger: #b4b5ba;
  --activeHamburger: #fff;
  --searchColor: #dad7d7;
  --rightBarSelecteleBg: #4a4e57;
  --dataTableOddRowBg: #1e293b;
  --dataTableEvenRowBg: #3f3f3f;
  --fontWeight: 300;
  --lwdGraphBg: #fff;
  --transition: background 3s ease, color 0.5s ease;

  --logoImage: darklogoImage;
  --defaultBgOfBgWidget: #919193;
  --defaultBgOfRadialWidget: #19181b;
  --higherZIndex: 10000;
  --bs1: #3c4049;
  --bs2: #202227;
  --danger_custom: #ff2929;
  --warning_custom: #dd9d00;
  --lowwarn_custom: #e3cd06;
}

/* :root {
  --textColorTooltip: #fff;
  --activeFeetBtnText: #fff;
  --defaultBorderColorForWidgets: #000;
  --verticalBarScaleRectBg: #f5f5f5;
  --popupBg: rgba(70, 69, 69, 0.86);
  --activeCustomerBg: #d0d5e3;
  --nav1HdngClrsame: #A9A9A9;
  --navBar2Bgsame: #eef1f5;
  --nav1BgClrsame: #D0D5E3;
  --navBar2Bg: #FFFFF7;
  --widgetBg: #FFFFF7;
  --nav1BgClr: #000000;
  --root_nav: #F5F5F5;
  --nav1HdngClr: #ff0000;
  --textColor: #000000;
  --activeTextColor: #ff0000;
  --headingColor: #ff0000;
  --menuListColor: #c3c3c3;
  
  --sidebarbg: #fff;
  --sidebarMnList: #2a2a2a;
  --sidebarButtonbg: #2a2a2a;
  --sidebarListColor: #ff0000;
  --sidebarListBgColor: #F5F5F5;
  --colorBoxBg: #EEF1F5;
  --central_bg: #ffffffe6;
  --colorBoxHeadingText: #000000;
  --colorBoxHeadBackround: #fdffe5;
  --colorBoxWhiteNum: #000000;
  --colorBoxPopUpBg: #fff;
  --colorBoxPopUpHeadingColor: #3C455C;
  --colorBoxPopUpInputBg: #EEF1F5;
  --dp-background-color: #EEF1F5;
  --colorBoxPopUpDeleteBg: #B4B5BA;
  --colorBoxPopUpSaveBg: #ff0000;
  --colorBoxPopUpDeleteText: #fff;
  --dropDownBg: #EEF1F5;
  --numericWidget: #414244;
  --numericValue: #fff;

  --rightSideBarOptions: #b4b8be;
  --rightSideBarOptionsSvgPath: #727272;
  --rightSideBarCloseBtn: #b4b5ba;
  --searchBarBg: #EEF1F5;
  --rightTwobars: #3C455C;
  --sideBarPopUpBg: #e5e5e5;
  --sideBarPopUpColor: #2a2a2a;
  --deleteDragRizeWindowBg: #2a2a2a;
  --deleteDragRizeWindowBgColor: #F5F5F5;
  --selectionWellPopupBg: #fff;
  --selectingTimeFeetColor: #3C455C;

  --selectingTimeFeetColorBg: #3f3f3f;
  --selectingTimeFeetColorBgActive: #ff0000;

  --botoomMinimaizerBodyBg: #D0D5E3;
  --gridLinesColor: #E5E5E5;
  --TandDCorrelationLeftBarBg: #fff;
  --toggleSliderBg: #B4B5BA;
 
  --liveBtnGreen: #12B822;
  --defalutHamburger: #6C7593;
  --activeHamburger: #ff0000;
  --searchColor: #6C7593;
  --rightBarSelecteleBg: #F5F5F5;
  --dataTableOddRowBg: #FFFFF7;
  --dataTableEvenRowBg: #f5f5f5;
  --fontWeight: 600;
  --lwdGraphBg: #fff;
  --transition: background 3s ease, color 0.5s ease;

  --logoImage: lightlogoImage;
  --defaultBgOfBgWidget: #d8d8ed;
  --defaultBgOfRadialWidget: #fff;
  --higherZIndex: 10000;
  --bs1: #ffffff;
  --bs2: #c1c2c5;
} */

/* poppins */
@font-face {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  src: url(./assets/font/Poppins/Poppins-ExtraBold.ttf) format("woff");
}

/* open Sans */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(./assets/font/Open_Sans/static/OpenSans/OpenSans-Light.ttf)
      format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(./assets/font/Open_Sans/static/OpenSans/OpenSans-Regular.ttf)
      format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"),
    url(./assets/font/Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf)
      format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url(./assets/font/Open_Sans/static/OpenSans/OpenSans-Bold.ttf)
      format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url(./assets/font/Open_Sans/static/OpenSans/OpenSans-ExtraBold.ttf)
      format("woff");
}

.dp__calendar_header_item {
  height: 20px !important;
  width: 18px !important;
  padding: 5px !important;
  font-size: 9px !important;
  text-align: center;
  flex-grow: 1;
}

.dp__cell_inner {
  height: 16px !important;
  width: 17px !important;
  font-size: 10px !important;
  /* color: white; */
}

.dp__month_year_select {
  width: 25% !important;
  height: 14px !important;
  font-size: 10px;
  /* color: white; */
}

.dp__menu {
  min-width: 150px !important;
}

.dp__input {
  outline: none !important;
  width: 100% !important;
  font-size: min(0.9vw, 12px) !important;
  font-weight: 600 !important;
  line-height: 0.1rem !important;
  padding: 4px 0 4px 6px !important;
  color: #ffffff;
  border: 0.5px solid #898989 !important;
  text-align: center !important;
  /* height: 15px !important; */
}

.dp__input_icon {
  top: 50% !important;
  width: 12% !important;
  color: var(--selectingTimeFeetColor) !important;
  padding: 2px !important;
  /* display: none; */
  width: 20px !important;
  height: 11px !important;
  right: 0 !important;
  left: auto !important;
}

.dp__clear_icon {
  display: none !important;
}

.dp__selection_preview {
  font-size: 0.53rem !important;
  color: #1976d2 !important;
}

body {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
}
</style>
