import Axios from "axios";
import constant from "../constant";
import api from "../dictionary/index";
import helper from "../../helper-services"
import jwt_decode from "jwt-decode";
import URLS from "../../config"


const handlers = {
  login: async (data) => {
    try {
      let response = await Axios.post(
        constant.BASE_URL + api.auth.LOGIN(),
        data
      );
      //  access_token, refresh_token, customer, scope  ARE ALREADY SETTING ONLY IF AGREEMENT & DISCLAIMER ARE TRUE
      //  IF VALUES SETS DIRECTLY LIKE BELOW, IF USER IS IN DISCLAIMER PAGE, IF CHANGES URL TO "/app", USER CAN DIRECTLY ACCESS DASHBOARD
      //  SO "access_token" SHOULD HAVE TO SET ONLY IF USER SIGNED THE AGREEMENTS
      //  PLEASE CHECK LOGIN.VUE AND DISCLAIMER.VUE COMPONENTS


      localStorage.setItem(
        "entityName",
        JSON.stringify(response.data.data.entityName)
      );
      localStorage.setItem(
        "entityName",
        JSON.stringify(response.data.data.entityName)
      );
      localStorage.setItem(
        "userId",
        JSON.stringify(response.data.data.user_id)
      );
      localStorage.setItem(
        "entityId",
        JSON.stringify(response.data.data.entityId)
      );
      localStorage.setItem(
        "roleName",
        JSON.stringify(response.data.data.roleName)
      );
      localStorage.setItem(
        "userName",
        JSON.stringify(response.data.data.userName)
      );
      // Axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.tokens.accessToken + "__bsgrsdfeassfk__tsdfodfkjhejhn_" + response.data.tokens.refreshToken;
      Axios.defaults.headers.common["Authorization"] =
        "Bearer " +
        response.data.tokens.accessToken

      // to get customer list code starts
      let gettingUserDetails = null;
      gettingUserDetails = response.data;
      let entities = null;
      let total_customer_list = [];
      if (
        gettingUserDetails.data.role_name && gettingUserDetails.data.role_name.toLowerCase().replace(/\s/g, "") ==
        "superadmin"
      ) {
        entities = await Axios.get(
          constant.DATASERVICES_URL + "entity/list"
        );
        entities.data.map((eachObj) => {
          total_customer_list.push(eachObj.entityName + '_' + eachObj.subEntityName)
        })
      } else {
        if (gettingUserDetails.data.entity_id) {
          let entityId = gettingUserDetails.data.entity_id;
          entities = await Axios.post(
            `${constant.DATASERVICES_URL}entity/search`,
            { entityId: entityId }
          );
          total_customer_list = [entities.data.entityName + '_' + entities.data.subEntityName];
        }
      }
      let obj = {
        userDetails: gettingUserDetails.data,
        customerList: total_customer_list,
      };
      localStorage.setItem("userInfo", JSON.stringify(obj));
      // to get customer list code ends

      console.log("new users Data>>>>from auth", response.data);
      return response;
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      console.log("login error", error);
      if (error.response) {
        return error.response;
      }
      return error;
    }
  },
  auth_login_old: async (data) => {
    try {
      let response = await Axios.post(
        constant.BASE_URL + api.auth.AUTH_LOGIN(),
        data
      );
      // STORING IDENTITY TOKENS TO LOCAL STORAGE
      localStorage.setItem("access_token", response.data.tokens.access_token);
      localStorage.setItem("refresh_token", response.data.tokens.refresh_token);
      // STORING USER DATA NAME LIST TO LOCAL STORAGE
      localStorage.setItem("user_details", JSON.stringify(response.data.data));
      // STORING CUSTOMER LIST TO LOCAL STORAGE
      localStorage.setItem("customer", JSON.stringify(response.data.customer));
      // STORING ENTITY/CLIENT NAME LIST TO LOCAL STORAGE
      localStorage.setItem(
        "entityName",
        JSON.stringify(response.data.data.entity_name)
      );
      // STORING SCOPES NAME LIST TO LOCAL STORAGE
      localStorage.setItem("scope", JSON.stringify(response.data.scope));
      // STORING USERID NAME LIST TO LOCAL STORAGE
      localStorage.setItem(
        "userId",
        JSON.stringify(response.data.data.user_Id)
      );

      localStorage.setItem(
        "entityId",
        JSON.stringify(response.data.data.entity_id)
      );
      localStorage.setItem(
        "roleName",
        JSON.stringify(response.data.data.role_name)
      );
      localStorage.setItem(
        "userName",
        JSON.stringify(response.data.data.user_name)
      );
      // SET COMBINATION OF ACCESS AND REFRESH TOKEN TO HEADER
      Axios.defaults.headers.common["Authorization"] =
        "Bearer " +
        response.data.tokens.accessToken

      // GET CUSTOMER LIST
      let gettingUserDetails = null;
      gettingUserDetails = response.data;
      let entities = null;
      // IF SUPERADMIN GET ALL ENTITY LIST
      if (
        gettingUserDetails.data.role_name &&
        gettingUserDetails.data.role_name.toLowerCase().replace(/\s/g, "") ==
        "superadmin"
      ) {
        let total_customer_list = [];
        entities = await Axios.get(
          constant.DATASERVICES_URL + "entity/list"
        );
        entities.data.map((eachObj) => {
          total_customer_list.push(eachObj.entityName + '_' + eachObj.subEntityName)
        })
        let obj = {
          userDetails: gettingUserDetails.data,
          customerList: total_customer_list,
        };
        console.log("userinfo data *********************** : ", obj)
        localStorage.setItem("userInfo", JSON.stringify(obj));
      } else {
        // IF NORMAL USER GET RESPECTIVE ENTITY LIST
        console.log("entities data ", gettingUserDetails);
        if (gettingUserDetails.data.entity_id) {
          let entityId = gettingUserDetails.data.entity_id;
          let total_customer_list = [];
          entities = await Axios.post(
            `${constant.DATASERVICES_URL}entity/search`,
            { entityId: entityId }
          );
          total_customer_list = [entities.data.entityName + '_' + entities.data.subEntityName];
          let obj = {
            userDetails: gettingUserDetails.data,
            customerList: total_customer_list,
          };
          console.log("entities data 1221 ********************* ", obj);
          localStorage.setItem("userInfo", JSON.stringify(obj));
        }
      }

      return response;
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      console.log("login error", error);
      if (error.response) {
        return error.response;
      }
      return error;
    }
  },
  auth_login: async (data) => {
    try {
      let response = await Axios.post(
        constant.BASE_URL + api.auth.AUTH_LOGIN(),
        data
      );
      return response;
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      console.log("login error", error);
      if (error.response) {
        return error.response;
      }
      return error;
    }
  },
  validate_session: async (data) => {
    try {
      let response = await Axios.post(
        constant.BASE_URL + api.auth.VALIDATE_SESSION(),
        data
      );
      return response;
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      console.log("login error", error);
      if (error.response) {
        return error.response;
      }
      return error;
    }
  },

  validate_reset: async (data) => {
    try {
      let response = await Axios.post(
        constant.BASE_URL + api.auth.VALIDATE_RESET_CODE(),
        data
      );
      return response;
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      console.log("login error", error);
      if (error.response) {
        return error.response;
      }
      return error;
    }
  },
  emp_logout: async (data) => {
    try {
      let response = await Axios.post(
        constant.BASE_URL + api.auth.AUTH_LOGOUT(),
        data
      );
      return response;
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      console.log("logout error", error);
      return error;
    }
  }
};
Axios.interceptors.response.use(
  async function (response) {
    console.log('response interceptor');
    if (response.headers["warning"] == "Validated With Refresh Token") {

    }
    return response;
  },
  async function (error) {
    if (error.response && error.response.status == 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/auth/login");
    } else {
      return Promise.reject(error);
    }
  }
);
// Axios.interceptors.request.use(
//   async function (config) {
//     try {
//       if (config.url.includes('/api/timezone')) {

//       } else {
//         if (!config.headers.Authorization && helper.getDetails("670023242:az")) {
//           config.headers["Authorization"] = `Bearer ${helper.getDetails("670023242:az").access_token}`
//         }
//         const {access_token,refresh_token} = helper.getDetails("670023242:az");
//         const {user_name} = helper.getDetails("apollodart").user;
//         const user = jwt_decode(access_token);
//         const isExpired = new Date() <= new Date(user.exp * 1000);
//         if (isExpired) {
//           return config;
//         }
//         const response = await fetch(URLS.HOST_URL.SERVER_URL + api.auth.REFRESH_TOKEN, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             token: refresh_token,
//             userName:user_name
//           }),
//         });
//         const newTokendata = await response.json();
//         helper.setDetails("670023242:az", newTokendata);
//          config.headers["Authorization"] = `Bearer ${newTokendata.access_token}`
//          return config;
//       }


//     } catch (error) {
//       console.log("error in request interceptor", error)
//     }
//     return config
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );
let isRefreshing = false; // Flag to indicate if a token refresh is in progress
let subscribers = []; // Queue to hold pending requests

function onTokenRefreshed(newToken) {
  subscribers.forEach((callback) => callback(newToken));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

Axios.interceptors.request.use(
  async function (config) {
    try {
      if (config.url.includes('/api/timezone')) {
        return config; // Skip token logic for specific endpoints
      }

      const userDetails = helper.getDetails("670023242:az");
      if (!userDetails) return config;

      let { access_token, refresh_token } = userDetails;
      const user_name = helper.getDetails("apollodart")?.user?.user_name;
      if (!access_token || !refresh_token || !user_name) return config;

      if (!config.headers.Authorization) {
        config.headers["Authorization"] = `Bearer ${access_token}`;
      }

      const user = jwt_decode(access_token);
      const isExpired = new Date() > new Date(user.exp * 1000);

      if (!isExpired) {
        return config; // Token is still valid
      }

      if (isRefreshing) {
        // Token refresh in progress; wait for new token
        return new Promise((resolve) => {
          addSubscriber((newToken) => {
            config.headers["Authorization"] = `Bearer ${newToken}`;
            resolve(config);
          });
        });
      }

      // Start token refresh
      isRefreshing = true;
      const response = await fetch(URLS.HOST_URL.SERVER_URL + api.auth.REFRESH_TOKEN, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: refresh_token, userName: user_name }),
      });

      if (!response.ok) {
        console.error('Failed to refresh token', response.statusText);
        isRefreshing = false;
        return config;
      }

      const newTokenData = await response.json();
      helper.setDetails("670023242:az", newTokenData);

      const newToken = newTokenData.access_token;
      config.headers["Authorization"] = `Bearer ${newToken}`;

      onTokenRefreshed(newToken); // Notify all waiting requests
      isRefreshing = false;

      return config;

    } catch (error) {
      console.error("Error in request interceptor:", error);
      isRefreshing = false; // Reset flag on error
      return config;
    }
  },
  function (error) {
    // Handle request error
    return Promise.reject(error);
  }
);

export default handlers;
