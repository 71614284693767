import {
  ALERT_COLOR,
  ENABLE_ACTIVE,
  DISABLE_ACTIVE,
  ENABLE_ASPECT,
  DISABLE_ASPECT,
  ENABLE_DRAGGABLE,
  DISABLE_DRAGGABLE,
  ENABLE_RESIZABLE,
  DISABLE_RESIZABLE,
  ENABLE_PARENT_LIMITATION,
  DISABLE_PARENT_LIMITATION,
  ENABLE_SNAP_TO_GRID,
  DISABLE_SNAP_TO_GRID,
  CHANGE_ZINDEX,
  ENABLE_BOTH_AXIS,
  ENABLE_X_AXIS,
  ENABLE_Y_AXIS,
  ENABLE_NONE_AXIS,
  CHANGE_HEIGHT,
  CHANGE_LEFT,
  CHANGE_MINH,
  CHANGE_MINW,
  CHANGE_TOP,
  CHANGE_WIDTH,
  ADD_NEW_RECT,
  TITLE_VISIBILITY,
  VALUE_VISIBILITY,
  UNIT_VISIBILITY,
  CHANGE_TITLE,
  CHANGE_UNIT,
  BORDER_VISIBILITY,
  DECIMALS,
  TITLE_COLOR,
  VALUE_COLOR,
  UNIT_COLOR,
  CHANGE_GAUGE_TITLE,
  VALUE_DECIMALS,
  GAUGE_BG_COLOR,
  GAUGE_START_ANGLE,
  GAUGE_END_ANGLE,
  GAUGE_START_RANGE,
  GAUGE_END_RANGE,
  CHANGE_TITLE_COLOR,
  CHANGE_VALUE_COLOR,
  CHANGE_UNIT_COLOR,
  CHANGE_BORDER_COLOR,
  CHANGE_BORDER_WIDTH,
  GAUGE_INTERVAL,
  GAUGE_LABEL_FONT,
  DELETE_WIDGET,
  BAR_START_RANGE,
  BAR_END_RANGE,
  BAR_INTERVAL,
  CHANGE_TEXT_COLOR,
  CHANGE_BACKGROUND_COLOR,
  DIRECTIONAL_START_RANGE,
  DIRECTIONAL_END_RANGE,
  DIRECTIONAL_START_RADAR,
  DIRECTIONAL_END_RADAR,
  DIRECTIONAL_GRID,
  CHANGE_COLOR,
  CHANGE_HEAD_BACKGROUND_COLOR,
  CHANGE_HEAD_COLOR,
  CHANGE_HEAD_FONT_SIZE,
  TITLE_FONT_SIZE,
  AUTO_FIT,
  VALUE_FONT_SIZE,
  UNTI_FONT_SIZE,
  VALUE_COLOR_GAUGE,
  TITLE_COLOR_GAUGE,
  NEEDLE_COLOR_GAUGE,
  SQUARE_GAUGE,
  CHANGE_TAG,
  CHANGE_START_RANGE,
  CHANGE_END_RANGE,
  NUMERIC_MEMONIC,
  NUMERIC_VALUE,
  BAR_MEMONIC,
  BAR_VALUE,
  GAUGE_MEMONIC,
  GAUGE_VALUE,
  NUMERIC_ALARM_END,
  NUMERIC_ALARM_START,
  CIRCULAR_ALARM_START,
  CIRCULAR_ALARM_END,
  LEFT_SIDE_BAR,
  DARK_MODE,
  LEFT_SIDE_DB_BAR,
  RIGT_SIDE_BAR,
  RIGT_SIDE_BAR_WIDTH,
  TOGGLE_DRAGGABLE,
  BLINK_ALERT,
  DRAGGABLE_ALL,
  RESIZABLE_ALL,
  UPDATE_ZINDEX,
  GAUGE_BREAKPOINT_VALUE,
  GAUGE_BREAKPOINT_COLOR,
  GAUGE_BREAKPOINT_ADD,
  GAUGE_BREAKPOINT_REMOVE,
  HAS_BREAKPOINT_CHANGED,
  SET_DISPLAY_ID,
  ADD_NEW_DISPLAY,
  CHANGE_LOG_TYPE,
  RADAR_VISIBILITY,
  DIRECTIONAL_DATA_POINTS,
  MINIMIZE_RECT,
  MAXIMIZE_WIDGET,
  PLOTLY_MNEMONIC_CHANGE,
  DELETE_DISPLAY,
  LINE_GRAPH_MNEMONIC_CHANGE,
  LINE_GRAPH_X_AXIS,
  LINE_GRAPH_Y_AXIS,
  LINE_GRAPH_GRID_COLOR,
  LINE_GRAPH_GRID_BACKGROUND,
  LINE_GRAPH_X_AXIS_LABEL_SIZE,
  LINE_GRAPH_Y_AXIS_LABEL_SIZE,
  LINE_GRAPH_TVD_X_AXIS,
  LINE_GRAPH_TVD_Y_AXIS,
  LINE_GRAPH_TVD_GRID_COLOR,
  LINE_GRAPH_TVD_GRID_BACKGROUND,
  LINE_GRAPH_TVD_X_AXIS_LABEL_SIZE,
  LINE_GRAPH_TVD_Y_AXIS_LABEL_SIZE,
  //Directional Difficulty
  GRAPH_LABEL_COLOR,
  GRAPH_COLOR,
  X_AXIS_LABEL_SIZE,
  Y_AXIS_LABEL_SIZE,
  GRID_COLOR,
  GHARPH_BACKGROUND,
  X_MANUAL_RANGE,
  X_START_RANGE,
  X_END_RANGE,
  Y_MANUAL_RANGE,
  Y_START_RANGE,
  Y_END_RANGE,

  /* Start or xy axis widgets */
  IS_SECONDARY_AXIS,
  AXIS_LABEL_COLOR,
  GRAPH_COLOR_XY_AXIS,
  GRID_COLOR_XY_AXIS,
  BACKGROUND_COLOR_XY_AXIS,
  XAXIS_LABEL_XY_AXIS,
  YAXIS_LABEL_XY_AXIS,
  AXIS_SECONDARY_LABEL_COLOR,
  AXIS_SECONDARY_SCALE_COLOR,
  AXIS_SECONDARY_LABEL_SIZE,
  PRIMART_X_AXIS_MNEMONICS,
  PRIMART_Y_AXIS_MNEMONICS,
  SECONDARY_Y_AXIS_MNEMONICS,
  /* Start or xy axis widgets */
} from "./mutation-types";
import units from "../../../js/mnemonics_units";

let index = -1;
const getIndex = (state, widgetId, displayId) => {
  for (let i = 0; i < state.rects.length; i++) {
    if (
      state.rects[i].widgetId == widgetId &&
      state.rects[i].displayId == displayId
    ) {
      index = i;
      return index;
    }
  }
};

export default {
  ["CHANGE_SAVED_NAME"](state, { backgroundId, savedName }) {
    for (let i = 0; i < state.rects.length; i++) {
      if (
        state.rects[i].name == "Background" &&
        state.rects[i].backgroundId == backgroundId
      ) {
        state.rects[i].savedName = savedName;
      }
    }
  },
  [DELETE_DISPLAY](state, { displayId, minimizedIndex }) {
    let index = -1;
    let tempAllRects = state.rects;
    for (let i = state.rects.length - 1; i >= 0; i--) {
      if (state.rects[i].displayId == displayId) {
        state.rects.splice(i, 1);
      }
    }
    state.whichIsActiveInOpentab3 = [];
    console.log("Alert---exicuted>>>>>deletedisplay rect");
  },
  // Common Mutations Starts
  [DRAGGABLE_ALL](state, { value, displayId }) {
    for (let i = 0; i < state.rects.length; i++) {
      if (displayId == state.rects[i].displayId)
        state.rects[i].draggable = value;
    }
  },
  [RESIZABLE_ALL](state, { displayId }) {
    for (let i = 0; i < state.rects.length; i++) {
      if (displayId == state.rects[i].displayId)
        state.rects[i].resizable = !state.rects[i].resizable;
    }
  },

  [UPDATE_ZINDEX](state, { id, value }) {
    state.rects[id].zIndex = 9999;
  },

  // [UPDATE_ZINDEX](state, { id, value }) {
  //
  //     if (state.rects[id]) {

  //         state.rects[id].zIndex = state.rect.rects.length + 1
  //     }
  // },

  // Common Mutations Ends
  [TOGGLE_DRAGGABLE](state, { displayId, widgetId }) {
    let idx = getIndex(state, widgetId, displayId);
    if (idx != undefined)
      state.rects[idx].draggable = !state.rects[idx].draggable;
  },
  ["TOGGLE_RESIZABLE"](state, { displayId, widgetId }) {
    let idx = getIndex(state, widgetId, displayId);
    if (idx != undefined)
      state.rects[idx].resizable = !state.rects[idx].resizable;
  },
  [LEFT_SIDE_BAR](state) {
    // if (!state.leftSideBarOpen) {
    //     state.rects.map(rect => {
    //         // rect.width -= 10;
    //         rect.left += 107;
    //         return rect
    //     })
    // } else {
    //     state.rects.map(rect => {
    //         // rect.width += 10;
    //         rect.left -= 107;
    //         return rect
    //     })
    // }

    state.leftSideBarOpen = !state.leftSideBarOpen;
  },
  [DARK_MODE](state, payload) {
    console.log("from store for theme:::::", payload);
    state.darkmode = payload;
    // state.darkmode = !state.darkmode;
  },
  [LEFT_SIDE_DB_BAR](state) {
    // if (!state.leftSideBarOpen) {
    //     state.rects.map(rect => {
    //         // rect.width -= 10;
    //         rect.left += 107;
    //         return rect
    //     })
    // } else {
    //     state.rects.map(rect => {
    //         // rect.width += 10;
    //         rect.left -= 107;
    //         return rect
    //     })
    // }

    state.leftSideDbBarOpen = !state.leftSideDbBarOpen;
  },
  [RIGT_SIDE_BAR](state, { id, value }) {
    if (state.toggle_right_Sidebar) {
      if (
        (state.selectedWidgetProps.displayId == value.displayId &&
          state.selectedWidgetProps.widgetId == value.widgetId) ||
        value == {}
      ) {
        state.toggle_right_Sidebar = !state.toggle_right_Sidebar;
        value = {};
      }
    } else {
      state.toggle_right_Sidebar = !state.toggle_right_Sidebar;
    }
    state.selectedWidgetProps = { ...value, index: id };
  },
  [RIGT_SIDE_BAR_WIDTH](state) {
    // if (!state.toggle_right_Sidebar) {
    //     state.rects.map(rect => {
    //         rect.left += 120;
    //         return rect
    //     })
    // } else {
    //     state.rects.map(rect => {
    //         rect.left -= 120;
    //         return rect
    //     })
    // }

    state.toggle_right_Sidebar = !state.toggle_right_Sidebar;
  },
  [CIRCULAR_ALARM_START](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].alertStartRange = value;
  },
  [CIRCULAR_ALARM_END](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].alertEndRange = value;
  },
  [NUMERIC_ALARM_END](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].alertEndRange = value;
  },
  [NUMERIC_ALARM_START](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].alertStartRange = value;
  },
  [NUMERIC_VALUE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].value = value;
  },
  [NUMERIC_MEMONIC](state, { displayId, widgetId, value, fullName }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value) {
      state.rects[idx].title = value;
      state.rects[idx].unit = units[value.toUpperCase()] || "Unit";
    }
    state.rects[idx].fullName = fullName;
  },
  [BAR_VALUE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].barValue = value;
  },
  [BAR_MEMONIC](state, { displayId, widgetId, value, fullName }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value) {
      state.rects[idx].title = value;
      state.rects[idx].unit = units[value.toUpperCase()] || "Unit";
    }
    state.rects[idx].fullName = fullName;
  },
  [VALUE_VISIBILITY](state, { displayId, widgetId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].valueDisplay = !state.rects[idx].valueDisplay;
  },
  [SQUARE_GAUGE](state, { widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].squareDisplay = !state.rects[idx].squareDisplay;
  },
  [BORDER_VISIBILITY](state, { displayId, widgetId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].borderDisplay = !state.rects[idx].borderDisplay;
    // if (state.rects[idx].name == 'numeric' || state.rects[idx].name == 'label')
    //     state.rects[idx].borderWidth = 1;
  },
  [TITLE_VISIBILITY](state, { displayId, widgetId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].titleDisplay = !state.rects[idx].titleDisplay;
  },
  [AUTO_FIT](state, { displayId, widgetId }) {
    let idx = getIndex(state, widgetId, displayId);
    console.log(state.rects[idx].autoFit);
    state.rects[idx].autoFit = !state.rects[idx].autoFit;
  },
  [TITLE_FONT_SIZE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].titleSize = value;
  },
  [VALUE_FONT_SIZE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].valueSize = value;
  },
  [UNTI_FONT_SIZE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].unitSize = value;
  },
  ["NUMERIC_UNIT"](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].unit = value;
  },
  ["NUMERIC_CHANGE_UNIT_CONVERSIN_FACTOR"](
    state,
    { displayId, widgetId, value }
  ) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].unit_conversion_factor = value;
  },
  ["CHANGE_UNIT_CONVERSIN_FACTOR"](
    state,
    { displayId, widgetId, value, unit }
  ) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].unit_conversion_factor = value;
    state.rects[idx].unit = unit;
  },
  [CHANGE_TITLE](state, { displayId, widgetId, value, fullName }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].title = value;
      state.rects[idx].unit = units[value.toUpperCase()] || "Unit";
    }
    state.rects[idx].fullName = fullName;
  },
  [TITLE_COLOR](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].titleColor = value;
  },
  [TITLE_COLOR_GAUGE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].saveTitleColor = value;
  },
  [VALUE_COLOR_GAUGE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);

    state.rects[idx].saveValueColor = value;
  },
  [NEEDLE_COLOR_GAUGE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].saveNeedleColor = value;
  },
  [VALUE_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].valueColor = value;
  },
  [UNIT_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].unitColor = value;
  },
  [CHANGE_TITLE_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].titleColor = value;
  },
  [CHANGE_VALUE_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].valueColor = value;
  },
  [CHANGE_UNIT_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].unitColor = value;
  },
  [CHANGE_UNIT](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].unit = value;
  },
  [SET_DISPLAY_ID](state, { displayId }) {
    let tempIds = state.displayIds;
    tempIds.push(displayId);
    state.displayIds = tempIds;

    // let tempDisplays = state.displays;
    // tempDisplays.push({ rects: state.rects });
    // state.displays = tempDisplays;
  },

  [CHANGE_BORDER_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].borderColor = value;
  },
  [CHANGE_BORDER_WIDTH](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].borderWidth = value;
  },

  [DECIMALS](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].decimalValue = parseInt(value);
  },
  [UNIT_VISIBILITY](state, { displayId, widgetId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].unitDisplay = !state.rects[idx].unitDisplay;
  },
  [ENABLE_ACTIVE](state, id) {
    state.rects[id].active = true;
  },
  enableDisplay(state, id) {
    state.displays[id].active = true;
  },
  disableDisplay(state, id) {
    state.displays[id].active = false;
  },

  [DISABLE_ACTIVE](state, id) {
    state.rects[id].active = false;
  },

  [ENABLE_ASPECT](state, id) {
    state.rects[id].aspectRatio = true;
  },
  [DISABLE_ASPECT](state, id) {
    state.rects[id].aspectRatio = false;
  },

  [ENABLE_DRAGGABLE](state, id) {
    state.rects[id].draggable = true;
  },
  [DISABLE_DRAGGABLE](state, id) {
    state.rects[id].draggable = false;
  },

  [ENABLE_RESIZABLE](state, id) {
    state.rects[id].resizable = true;
  },
  [DISABLE_RESIZABLE](state, id) {
    state.rects[id].resizable = false;
  },

  [ENABLE_SNAP_TO_GRID](state, id) {
    state.rects[id].snapToGrid = true;
  },
  [DISABLE_SNAP_TO_GRID](state, id) {
    state.rects[id].snapToGrid = false;
  },

  [ENABLE_BOTH_AXIS](state, id) {
    state.rects[id].axis = "both";
  },
  [ENABLE_NONE_AXIS](state, id) {
    state.rects[id].axis = "none";
  },
  [ENABLE_X_AXIS](state, id) {
    state.rects[id].axis = "x";
  },
  [ENABLE_Y_AXIS](state, id) {
    state.rects[id].axis = "y";
  },

  [ENABLE_PARENT_LIMITATION](state, id) {
    state.rects[id].parentLim = true;
  },
  [DISABLE_PARENT_LIMITATION](state, id) {
    state.rects[id].parentLim = false;
  },

  [CHANGE_ZINDEX](state, payload) {
    state.rects[payload.id].zIndex = payload.zIndex;
  },

  setDimensions(state, payload) {
    let i = payload.index;
    state.rects[i].height = payload.height;
    state.rects[i].width = payload.width;
    state.rects[i].top = payload.top;
    state.rects[i].left = payload.left;
    // state.rects[i].config = payload.config;
  },

  [CHANGE_HEIGHT](state, payload) {
    state.rects[payload].height = payload.height;
  },

  [CHANGE_WIDTH](state, payload) {
    state.rects[payload].width = payload.width;
  },

  [CHANGE_TOP](state, payload) {
    //
    state.rects[payload].top = payload.top;
  },

  [CHANGE_LEFT](state, payload) {
    state.rects[payload].left = payload.left;
  },

  [CHANGE_MINH](state, payload) {
    state.rects[payload.id].minh = payload.minh;
  },

  [CHANGE_MINW](state, payload) {
    state.rects[payload.id].minw = payload.minw;
  },
  [ADD_NEW_RECT](state, rectProps) {
    let tempRectProps = {
      ...rectProps,
      titleDisplay: true,
      valueDisplay: true,
    };

    state.rects.push(tempRectProps);
  },

  addNewDisplay(state, props) {
    state.displays.push(props);
  },

  // MUTATIONS FOR CIRCULAR GAUGE
  // [GAUGE_BREAKPOINT_ADD](state, { id, gaugeBreakPoints }) {

  [GAUGE_BREAKPOINT_ADD](
    state,
    { displayId, widgetId, isVerticalBar, value, color }
  ) {
    let tempBreakPoints = value;
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].breakPoints = JSON.parse(JSON.stringify(tempBreakPoints));
    if (!isVerticalBar) state.rects[idx].arcCount = tempBreakPoints.length;
  },
  [GAUGE_BREAKPOINT_REMOVE](state, { widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    let tempBreakPoints = state.rects[idx].breakPoints;
    // tempBreakPoints.sort((a, b) => parseInt(a.value) < parseInt(b.value))
    tempBreakPoints.pop();
    state.rects[idx].breakPoints = tempBreakPoints;
  },
  [HAS_BREAKPOINT_CHANGED](state, { displayId, widgetId }) {
    let idx = getIndex(state, widgetId, displayId);
    if (state.rects[idx])
      state.rects[idx].isChanged = !state.rects[idx].isChanged;
  },
  [GAUGE_BREAKPOINT_VALUE](
    state,
    { widgetId, value, breakPointIndex, displayId }
  ) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].breakPoints[breakPointIndex].value = value;
  },
  [GAUGE_BREAKPOINT_COLOR](
    state,
    { widgetId, value, breakPointIndex, displayId }
  ) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].breakPoints[breakPointIndex].color = value;
  },

  [GAUGE_VALUE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].niddleAngle = value;
  },
  [GAUGE_MEMONIC](state, { displayId, widgetId, value, fullName }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value) {
      state.rects[idx].title = value;
      state.rects[idx].unit = units[value.toUpperCase()] || "Unit";
    }
    state.rects[idx].fullName = fullName;
  },

  [CHANGE_GAUGE_TITLE](state, { displayId, widgetId, value, fullName }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value) {
      state.rects[idx].title = value;
      state.rects[idx].unit = units[value.toUpperCase()] || "Unit";
    }
    state.rects[idx].fullName = fullName;
  },
  [VALUE_DECIMALS](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].valueDecimals = parseInt(value);
  },
  [GAUGE_BG_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].bgColor = value;
  },
  ["GAUGE_SAVE_COLOR"](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);

    state.rects[idx].saveColor = value;
  },
  [GAUGE_START_ANGLE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].startAngle = value;
  },
  [GAUGE_END_ANGLE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].endAngle = value;
  },
  [GAUGE_START_RANGE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].rangeStart = value;
  },
  [GAUGE_END_RANGE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].rangeEnd = value;
  },
  [GAUGE_INTERVAL](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].interval = parseFloat(value);
  },
  [GAUGE_LABEL_FONT](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].labelFontPercent = parseFloat(value);
  },

  [DELETE_WIDGET](state, { displayId, widgetId, minimizedIndex }) {
    let index = -1;
    let name = "";
    for (let i = 0; i < state.rects.length; i++) {
      if (
        state.rects[i].displayId == displayId &&
        state.rects[i].widgetId == widgetId
      ) {
        index = i;
        name = state.rects[i].name;
        continue;
      }
    }
    if (index > -1) {
      state.rects.splice(index, 1);
    }
    if (minimizedIndex != undefined) {
      state.minimizedWidgets.splice(minimizedIndex, 1);
    }
    console.log("Alert---exicuted in rect", name);
    // state.rects = state.rects.splice(widgetId, 1)
    if (state.whichIsActiveInOpentab3.includes(name)) {
      state.whichIsActiveInOpentab3 = state.whichIsActiveInOpentab3.filter(
        (each) => each !== name
      );
    }
  },

  [BAR_START_RANGE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].rangeStart = parseFloat(value);
  },
  [BAR_END_RANGE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].rangeEnd = parseFloat(value);
  },
  [BAR_INTERVAL](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].interval = parseFloat(value);
  },

  // DIRECTIONAL WIDGET MUTATIONS
  [CHANGE_TEXT_COLOR](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].textColor = value;
  },
  [CHANGE_BACKGROUND_COLOR](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    if (state.rects[idx]) state.rects[idx].backgroundColor = value;
  },
  changeCardBackgroundColor(state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    if (state.rects[idx]) state.rects[idx].cardBackground = value;
  },
  [DIRECTIONAL_START_RANGE](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].rangeStart = value;
  },
  [DIRECTIONAL_END_RANGE](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].rangeEnd = value;
  },
  [DIRECTIONAL_START_RADAR](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].radarStart = value - 30;
    state.rects[idx].radarEnd = value + 30;
  },
  [DIRECTIONAL_END_RADAR](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].radarEnd = value;
  },
  [DIRECTIONAL_GRID](state, { widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].showGrid = !state.rects[idx].showGrid;
  },

  [CHANGE_LOG_TYPE](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].log_type = value;
  },

  [RADAR_VISIBILITY](state, { widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].isRadarVisible = !state.rects[idx].isRadarVisible;
  },
  [DIRECTIONAL_DATA_POINTS](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].numberOfDataPoints = value;
  },

  // Data Table Mutations
  [CHANGE_TAG](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].tag = value;
  },

  [CHANGE_START_RANGE](state, { value, tag, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx][`${tag.split(" ").join("").toLowerCase()}StartRange`] =
      value;
  },

  [CHANGE_END_RANGE](state, { value, tag, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx][`${tag.split(" ").join("").toLowerCase()}EndRange`] =
      value;
  },

  [CHANGE_COLOR](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value && state.rects[idx].gridColor) {
      console.log("~~~~~~~~~~~~", value, state.rects[idx].gridColor);
      state.rects[idx].gridColor = value;
    }
  },

  [CHANGE_HEAD_COLOR](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].headColor = value;
  },
  [CHANGE_HEAD_BACKGROUND_COLOR](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].headBackground = value;
  },

  [CHANGE_HEAD_FONT_SIZE](state, { value, widgetId, displayId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].headFontSize = value;
  },

  [ALERT_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);

    state.rects[idx].alertColor = value;
  },
  [BLINK_ALERT](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].blinkAlert = value;
  },

  [MINIMIZE_RECT](state, { displayId, widgetId }) {
    for (let i = 0; i < state.rects.length; i++) {
      if (
        state.rects[i].widgetId == widgetId &&
        state.rects[i].displayId == displayId
      ) {
        state.rects[i].isMinimize = true;
        state.minimizedWidgets.push(state.rects[i]);
      }
    }
  },
  [MAXIMIZE_WIDGET](state, { displayId, widgetId, minimizedIndex }) {
    for (let i = 0; i < state.rects.length; i++) {
      if (
        state.rects[i].widgetId == widgetId &&
        state.rects[i].displayId == displayId
      ) {
        state.rects[i].isMinimize = false;
        state.minimizedWidgets.splice(minimizedIndex, 1);
      }
    }
  },

  [PLOTLY_MNEMONIC_CHANGE](state, { displayId, widgetId, value, index }) {
    let idx = getIndex(state, widgetId, displayId);

    // if (state.rects[idx].tags) {
    //     state.rects[idx].tags[index] = value
    // }
    // else {
    //     state.rects[idx].tags = {}
    //     state.rects[idx].tags[index] = value
    // }
    if (index == 0) {
      state.rects[idx].mnemonicTag1 = value;
    } else if (index == 1) {
      state.rects[idx].mnemonicTag2 = value;
    } else if (index == 2) {
      state.rects[idx].mnemonicTag3 = value;
    } else if (index == 3) {
      state.rects[idx].mnemonicTag4 = value;
    }
  },

  // Line Graph

  [LINE_GRAPH_MNEMONIC_CHANGE](state, { displayId, widgetId, value, index }) {
    let idx = getIndex(state, widgetId, displayId);

    if (index == 0) {
      state.rects[idx].mnemonicTag1 = value;
    } else if (index == 1) {
      state.rects[idx].mnemonicTag2 = value;
    }
  },

  [LINE_GRAPH_X_AXIS](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].xAxisLabel = value;
  },
  ["toggleMnemonics"](state, { displayId, widgetId }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].isSwitched = !state.rects[idx].isSwitched;
  },
  ["actualColor"](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].actualColor = value;
  },
  ["planColor"](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].planColor = value;
  },
  [LINE_GRAPH_Y_AXIS](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].yAxisLabel = value;
  },
  [LINE_GRAPH_GRID_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].gridColor = value;
  },
  [LINE_GRAPH_GRID_BACKGROUND](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].gridBackground = value;
  },
  [LINE_GRAPH_X_AXIS_LABEL_SIZE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].xAxisLabelSize = value;
  },
  [LINE_GRAPH_Y_AXIS_LABEL_SIZE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].yAxisLabelSize = value;
  },

  // Line Graph Tvd
  [LINE_GRAPH_TVD_X_AXIS](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].xAxisLabel = value;
  },
  [LINE_GRAPH_TVD_Y_AXIS](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].yAxisLabel = value;
  },
  [LINE_GRAPH_TVD_GRID_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].gridColor = value;
  },
  [LINE_GRAPH_TVD_GRID_BACKGROUND](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].gridBackground = value;
  },
  [LINE_GRAPH_TVD_X_AXIS_LABEL_SIZE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].xAxisLabelSize = value;
  },
  [LINE_GRAPH_TVD_Y_AXIS_LABEL_SIZE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].yAxisLabelSize = value;
  },
  setConfig(state, payload) {
    let widId = payload.widId;
    let disId = payload.disId;
    let idx = getIndex(state, widId, disId);
    state.rects[idx].plotConfig = payload.config;
  },
  setCorrelationConfig(state, payload) {
    let widId = payload.widId;
    let disId = payload.disId;
    let idx = getIndex(state, widId, disId);
    state.rects[idx].plotConfig = { ...payload.config, option: payload.option };
    // if (!state.rects[idx].plotConfig)
    //     state.rects[idx].plotConfig = []
    // state.rects[idx].plotConfig[payload.trendIndex] = payload.config;
  },
  SET_DIS_SCREEN(state, payload) {
    state.disScreenWidgetId = payload.widgetId;
  },
  ["BHASTARTTIME"](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].bhaStartTime = value;
  },
  ["BHAENDTIME"](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].bhaEndTime = value;
  },
  setCorrelationSelectedWell(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (Object.keys(value).length) {
      state.rects[idx].correlationSelectedWells = { ...value };
    } else {
      state.rects[idx].correlationSelectedWells = {};
    }
  },
  wellsToRelate(
    state,
    { displayId, widgetId, widgetIndex, value, checked, index, isReset }
  ) {
    let idx = getIndex(state, widgetId, displayId);
    console.log(
      "checking idx::::::in store wellsToRelate:::",
      idx,
      displayId,
      widgetId,
      value,
      checked,
      state.rects[idx]
    );
    if (!isReset) {
      let wells = [];
      if (state.rects[idx].wellsToRelate) {
        wells = [...state.rects[idx].wellsToRelate];
        // wells = JSON.parse(JSON.stringify(state.rects[widgetIndex].wellsToRelate))
      }
      if (checked) {
        wells.push(value);
      } else {
        for (let i in wells) {
          if (wells[i].name == value.name) {
            wells.splice(i, 1);
          }
        }
      }

      if (wells.length < 4) {
        if (checked && value.state == "UPDATING") {
          let b = wells[wells.length - 1];
          wells[wells.length - 1] = wells[0];
          wells[0] = b;
        }
        state.rects[idx].wellsToRelate = [...wells];
      }
    } else {
      state.rects[idx].wellsToRelate = [];
    }
  },

  wellSection(state, payload) {
    state.rects[payload.widgetIndex].wellSection = payload.value;
  },

  wellRelationOptimization(
    state,
    { displayId, widgetId, widgetIndex, value, checked }
  ) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].wellRelationOptimization = value;
    state.wellRelationOptimization = value;
    console.log(
      "checking rects in optimaization_in store:::::",
      state.rects[idx],
      idx
    );
  },

  setRelationDisplay(state, payload) {
    state.rects[payload.widgetIndex].relationWellData = payload;
  },
  mousemove(state, payload) {},
  mouseout(state, payload) {},
  yAxisValue(state, payload) {
    state.rects[payload.widgetIndex].yAxisValue = payload.yAxisValue;
  },
  lockevent(state, payload) {
    state.rects[payload.widgetIndex].locker = payload.locker;
  },
  mousescroll(state, payload) {},
  toolTipToggle(state, payload) {
    state.rects[payload.widgetIndex].isShowTooltip = payload.isShowTooltip;
  },
  toolZoomToggle(state, payload) {
    state.rects[payload.widgetIndex].isZoom = payload.isZoom;
  },
  zoomStart(state, payload) {},
  zoomEnd(state, payload) {},
  setCurveProps(state, payload) {},
  selectFeet(state, payload) {},
  selectScrollFeet(state, payload) {},
  modeToogle(state, payload) {},
  lteralModeToogle(state, payload) {},
  syncScale(state, payload) {},

  trajectoryRangeChange(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx] = { ...state.rects[idx], ...value };
  },
  trajectoryToggleAutoScale(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx] = { ...state.rects[idx], ...value };
  },

  changeDrillPipeLength(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dry_pipe_length = value;
    }
  },
  changeSlugDrillPipeLength(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].slug_dry_pipe_length = value;
    }
  },
  changeSlugWeight(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].slug_weight = value;
    }
  },
  changeAnnulusVolume(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].annular_capacity = value;
    }
  },
  changeSpotColumn(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].height_spot_column = value;
    }
  },
  changeAnnulusSlugVolume(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].annular_slug_volume = value;
    }
  },
  changeSlugVolume(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].slug_volume = value;
    }
  },
  changeWashoutFactor(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].washout_factor = value;
    }
  },
  changeDrillStringVolume(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].drill_string_volume_left = value;
    }
  },
  changeStrokeRequired(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].stroke_required_displace_surface = value;
      console.log(" value ~~~ ", state.rects[idx]);
    }
  },
  //swab
  //Static
  changeTripeDepth(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].trip_Depth = value;
    }
  },
  changeTripeDepthTVD(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].trip_Depth_TVD = value;
    }
  },
  changeHoleSize(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].hole_size = value;
    }
  },
  changeDC1OD(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dc1_od = value;
    }
  },
  changeDC1ID(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dc1_id = value;
    }
  },
  changeDC1Length(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dc1_length = value;
    }
  },
  changeDC2OD(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dc2_od = value;
    }
  },
  changeDC2ID(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dc2_id = value;
    }
  },
  changeDC2Length(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dc2_length = value;
    }
  },
  changeHWDPOD(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].hwdp_od = value;
    }
  },
  changeHWDPID(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].hwdp_id = value;
    }
  },
  changeHWDPLength(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].hwdp_length = value;
    }
  },
  changeDPOD(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dp_od = value;
    }
  },
  changeDPID(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dp_id = value;
    }
  },
  changeDPLength(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dp_length = value;
    }
  },
  changeMudDensity(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].mud_wght = value;
    }
  },
  changeR300(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].r300 = value;
    }
  },
  changeR600(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].r600 = value;
    }
  },
  changeTripMode(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].selected_tripmode = value;
    }
  },
  changeTripStatus(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].selected_tripstatus = value;
    }
  },
  changeFlowIndex(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].flow_index = value;
    }
  },
  changeConsistencyIndex(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].Consistency_index = value;
    }
  },
  changePlasticViscosity(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].plastic_Viscosity = value;
    }
  },
  changSwabSearchUnitFactor(state, { displayId, widgetId, key, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (Object.hasOwn(state.rects[idx], "unitConversion")) {
      state.rects[idx].unitConversion[key] = value;
    } else {
      state.rects[idx]["unitConversion"] = {};
      state.rects[idx].unitConversion[key] = value;
    }
  },
  // Swab Live
  changeTripeDepthLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].trip_Depth_Live = value;
    }
  },
  changeTripeDepthTVDLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].trip_Depth_TVD_Live = value;
    }
  },
  changeHoleSizeLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].hole_size_Live = value;
    }
  },
  changeDC1ODLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dc1_od_Live = value;
    }
  },
  changeDC1IDLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dc1_id_Live = value;
    }
  },
  changeDC1LengthLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dc1_length_Live = value;
    }
  },
  changeDC2ODLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dc2_od_Live = value;
    }
  },
  changeDC2IDLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dc2_id_Live = value;
    }
  },
  changeDC2LengthLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dc2_length_Live = value;
    }
  },
  changeHWDPODLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].hwdp_od_Live = value;
    }
  },
  changeHWDPIDLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].hwdp_id_Live = value;
    }
  },
  changeHWDPLengthLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].hwdp_length_Live = value;
    }
  },
  changeDPODLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dp_od_Live = value;
    }
  },
  changeDPIDLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dp_id_Live = value;
    }
  },
  changeDPLengthLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].dp_length_Live = value;
    }
  },
  changeCasingOD(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].cassing_od = value;
    }
  },
  changeCassingID(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].cassing_id = value;
    }
  },
  changeCassingLength(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].cassing_length = value;
    }
  },
  changeMudDensityLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].mud_wght_Live = value;
    }
  },
  changeR300Live(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].r300_Live = value;
    }
  },
  changeR600Live(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].r600_Live = value;
    }
  },
  changeTripModeLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].selected_tripmode_Live = value;
    }
  },
  changeTripStatusLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].selected_tripstatus_Live = value;
    }
  },
  changeFlowIndexLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].flow_index_Live = value;
    }
  },
  changeConsistencyIndexLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].Consistency_index_Live = value;
    }
  },
  changePlasticViscosityLive(state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value != null) {
      state.rects[idx].plastic_Viscosity_Live = value;
    }
  },
  swabSurgeRealTimedata(state, { displayId, widgetId, value, field }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value !== null) {
      state.rects[idx][field] = value;
    }
  },
  LwdData(state, { displayId, widgetId, value, field }) {
    let idx = getIndex(state, widgetId, displayId);
    if (value != "undefined" && value !== null) {
      state.rects[idx][field] = value;
    }
  },
  addNewRectForVerticalBar(state, data) {
    if (data != null && data != "undefined") {
      state.rects.push(data);
    }
  },
  clickedGraph(state, value) {
    // let idx = getIndex(state, widgetId, displayId);
    // console.log("idx in clicked graph ", idx)
    console.log("value ", value);
    if (value != "undefined" && value != null) {
      state.rects.clicked_graph = value;
      console.log("state.rects.clicked_graph ", state.rects.clicked_graph);
    }
  },

  /*Start Directional Difficulty*/
  [GRAPH_LABEL_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx]["graphLabelColor"] = value;
  },
  [GRAPH_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx]["graphColor"] = value;
    // state.rects[idx]={...state.rects[idx],graphColor:value};
  },
  [X_AXIS_LABEL_SIZE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx]["xaxisLabelSize"] = value;
  },
  [Y_AXIS_LABEL_SIZE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx]["yaxisLabelSize"] = value;
  },
  [GRID_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx]["gridColor"] = value;
  },
  [GHARPH_BACKGROUND](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx]["gridBackgroundColor"] = value;
  },
  [GHARPH_BACKGROUND](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx]["gridBackgroundColor"] = value;
  },
  [X_MANUAL_RANGE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx]["xManualRange"] = value;
  },
  [X_START_RANGE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx]["xStartRange"] = value;
  },
  [X_END_RANGE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx]["xEndRange"] = value;
  },
  [Y_MANUAL_RANGE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx]["yManualRange"] = value;
  },
  [Y_START_RANGE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx]["yStartRange"] = value;
  },
  [Y_END_RANGE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx]["yEndRange"] = value;
  },
  /*End Directional Difficulty*/
  /* Start of xy axis widgets */
  [IS_SECONDARY_AXIS](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].isSecondaryAxis = value;
  },
  [AXIS_LABEL_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].axisLabelColor = value;
  },
  [GRAPH_COLOR_XY_AXIS](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].graphScaleColor = value;
  },
  [GRID_COLOR_XY_AXIS](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].gridColor = value;
  },
  [BACKGROUND_COLOR_XY_AXIS](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].graphBackgroundColor = value;
  },
  [XAXIS_LABEL_XY_AXIS](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].xAxisLabelSize = value;
  },
  [YAXIS_LABEL_XY_AXIS](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].yAxisLabelSize = value;
  },
  [AXIS_SECONDARY_LABEL_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].sevondaryAxisLabelColor = value;
  },
  [AXIS_SECONDARY_SCALE_COLOR](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].graphSecondaryScaleColor = value;
  },
  [AXIS_SECONDARY_LABEL_SIZE](state, { displayId, widgetId, value }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].yAxisSecondaryLabelSize = value;
  },
  [PRIMART_X_AXIS_MNEMONICS](
    state,
    { displayId, widgetId, value, fullName, unit }
  ) {
    let idx = getIndex(state, widgetId, displayId);
    if (unit) {
      state.rects[idx].primaryXaxisUnit = unit || "Unit";
    }
    state.rects[idx].primaryXaxisFullName = { name: fullName, value: value };
  },
  [PRIMART_Y_AXIS_MNEMONICS](
    state,
    { displayId, widgetId, value, fullName, unit }
  ) {
    let idx = getIndex(state, widgetId, displayId);
    if (unit) {
      state.rects[idx].primaryYaxisUnit = unit || "Unit";
    }
    state.rects[idx].primaryYaxisFullName = { name: fullName, value: value };
  },
  ["changePrimaryyunitfactor"](state, { displayId, widgetId, factor, unit }) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].primaryYaxisUnit = unit;
    state.rects[idx].primaryYaxisUnitFactor = factor;
  },
  [SECONDARY_Y_AXIS_MNEMONICS](
    state,
    { displayId, widgetId, value, fullName, unit }
  ) {
    let idx = getIndex(state, widgetId, displayId);
    if (unit) {
      state.rects[idx].secondryYaxisUnit = unit || "Unit";
    }
    state.rects[idx].secondaryYaxisFullName = { name: fullName, value: value };
  },
  ["changeSecondaryYaxisUnitfactor"](
    state,
    { displayId, widgetId, factor, unit }
  ) {
    let idx = getIndex(state, widgetId, displayId);
    state.rects[idx].secondryYaxisUnit = unit;
    state.rects[idx].secondaryYaxisUnitFactor = factor;
  },
  /* End of xy axis widgets */
};
